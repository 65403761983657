.main {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 5rem;
  flex-grow: 1;
}
.font300 {
  font-weight: 300;
  color: #29afca;
  font-size: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.spaceBottom {
  padding-bottom: 1rem;
}
