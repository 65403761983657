.main {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 5rem;
  flex-grow: 1;
}

.column {
  display: flex;
  flex-direction: column;
}

.rowTop {
  display: flex;
  align-items: flex-start;
}

.title {
  font-weight: 300;
  color: var(--color-light-blue);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.leftCell {
  width: 50%;
  padding-right: 1rem;
}

.rightCell {
  width: 50%;
  padding-left: 1rem;
}

.bottomSpace {
  padding-bottom: 3rem;
}

.spaceBottom {
  padding-bottom: 0.5rem;
}

.textTitle {
  font-weight: 700;
  color: var(--color-light-blue);
}

.rightCell .textTitle {
  font-weight: 700;
  color: var(--color-light-blue);
  padding-bottom: 0.5rem;
}

.rightCell .spaceBottom {
  padding-bottom: 1rem;
}

.content a {
  text-decoration: underline;
}
