.title {
  font-weight: 700;
  font-size: 1rem;
}
.textCenter {
  text-align: center;
  padding: 0 2rem 4rem 3rem;
}

.descrTitle {
  font-weight: 900;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 2rem;
}

.titleInputBetfair {
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 0.5rem;
}

.paddingBottom {
  padding-bottom: 1.5rem;
}

.paddingTop .inputCheckBox:checked,
.paddingTop .inputCheckBox span {
  color: var(--color-light-blue);
}

.selects {
  color: #1699a0;
  border-color: #1699a0;
  border-radius: 4px;
  border: solid 1px;
  margin-bottom: 2rem;
}

.titleInput {
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 2rem;
}

.input {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.5rem;
  font-size: 1rem;
  border-style: solid;
  border-color: #29afca;
  border-width: 1px;
}

.inputInvalid {
  border-color: red;
}

.row {
  display: flex;
  align-items: flex-start;
}

.leftCell {
  width: 50%;
  padding-left: 2rem;
  text-align: left;
}

.row .confBtnAct,
.row .confBtnDeact {
  background-color: var(--color-active);
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border: none;
  font-size: 1rem;
  vertical-align: -0.05rem;
  margin-left: 0.5rem;
  margin-top: 0.6rem;
  text-transform: uppercase;
  align-self: flex-start;
}

.row .confBtnDeact {
  background-color: var(--color-gray);
}

.row .vendorText {
  color: var(--color-active);
  border-color: var(--color-active);
  cursor: pointer;
}

.row .vendorClientInput {
  color: var(--color-active);
  border-color: var(--color-active);
  cursor: pointer;
}

.betFairForm {
  width: 100%;
  display: flex;
  align-items: center;
}

.betFairForm .textInputBox {
  display: flex;
  width: 60%;
}

.betFairForm .textInputBox .vendorClientInput,
.betFairForm .textInputBox .vendorClientInputError {
  width: 100%;
}

.betFairForm .textInputBox .vendorClientInput fieldset {
  border-color: var(--color-active);
  border-width: 2px;
}

.betFairForm
  .textInputBox
  .vendorClientInput
  label[id="bf-vendor-client-id-label"] {
  color: var(--color-active);
}

.betFairForm .textInputBox .vendorClientInputError {
  color: red;
  border-color: red;
  cursor: pointer;
}

.betFairForm .textInputBox .vendorClientInputError fieldset {
  border-color: red;
  border-width: 2px;
}

.countrySelectForm {
  padding-top: 1.5rem;
}

.countrySelectForm .countrySelectInput,
.countrySelectForm .countrySelectInput label,
.countrySelectForm .countrySelectInput label:focus {
  color: var(--color-active);
}

.countrySelectForm .countrySelectInput {
  width: 100%;
}

.countrySelectInput > div:first-of-type {
  width: 60%;
}

.textFieldBox {
  padding-top: 1.5rem;
  width: 100%;
}

.invoice {
  display: flex;
}

.inputDetails {
  display: flex;
  width: 60%;
}

.invoiceDetails .countrySelectForm .countrySelectInput fieldset {
  border-width: 2px;
  border-color: var(--color-active);
}

.inputDetails .formControl {
  width: 100%;
}

.inputDetails .formControl label {
  color: var(--color-active);
  border-color: var(--color-active);
}

.invoice .checkIcon {
  align-self: center;
}

.textFieldBox .inputDetails .formControl .textField > div:first-of-type,
.textFieldBox .inputDetails .formControl .textField fieldset {
  border-color: var(--color-active);
  border-width: 2px;
}

.inputDetails .formControl label[class~="Mui-error"] {
  color: red;
}

.rightCell {
  width: 50%;
  padding: 0 2rem;
}

.cell {
  background-color: var(--color-pale);
  padding-left: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  text-align: left;
}

.rightCellTitle {
  color: #29afca;
  padding-bottom: 2rem;
  font-weight: bold;
}

.ammount {
  text-align: right;
}

.centerItems {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 1rem;
}

.alignRight {
  float: right;
}

.totalSize {
  font-size: 1.3rem;
}

.hidden {
  display: none;
}

.formCell {
  background-color: white;
  padding-left: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  margin-left: 0rem;
  margin-right: 2rem;
}

.newForm {
  padding-top: 1rem;
  font-weight: bold;
}

.emptySpace {
  padding-top: 3rem;
}

.summary {
  padding-top: 1rem;
}

.continueBtn {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  width: fit-content;
}

.continueBtn .BtnAct,
.continueBtn .BtnDeact {
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  border: none;
  font-size: 1rem;
  vertical-align: -0.05rem;

  margin-top: 1rem;
  text-transform: uppercase;
}

.continueBtn .BtnAct {
  background-color: var(--color-active);
}

.continueBtn .BtnDeact {
  background-color: var(--color-gray);
}

.icons {
  align-self: center;
  display: flex;
  height: 100%;
}

.icon {
  color: var(--color-active);
  margin: 0 0.25rem;
}

.betfairVendor {
  padding-top: 1rem;
}

@media (max-width: 768px) {
  .row {
    display: flex;
    flex-direction: column;
  }

  .leftCell {
    padding: 0 0 1rem 0;
    width: 100%;
  }

  .formCell {
    margin: 0;
  }

  .rightCell {
    padding: 0;
    width: 100%;
  }

  /* .inputWrapper {
    padding-left: 1rem;
    width: 100%;
  } */
}

@media (max-width: 480px) {
  .leftCell {
    padding: 0 1rem 1rem 1rem;
  }

  .rightCell {
    padding: 0 1rem;
  }
}
