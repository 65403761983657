.main {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 5rem;
  /* display: flex; */
  text-align: left;
  flex-grow: 1;
}

.column {
  display: flex;
  flex-direction: column;
}

.rowTop {
  display: flex;
  align-items: flex-start;
}

.title {
  font-weight: 300;
  color: var(--color-light-blue);
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.leftCell {
  height: 100%;
  width: 50%;
  padding-right: 1rem;
}

.sticky {
  position: sticky;
}

.spaceLeft {
  padding-left: 1rem;
}

.rightCell {
  width: 50%;
  padding-left: 1rem;
}

.smallTextGray {
  font-size: 0.7rem;
  color: var(--color-gray);
  padding-top: 1rem;
}

.smallText {
  font-size: 0.7rem;
}
.centerItems {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 1rem;
}

.vendorText {
  font-size: 0.7rem;
  color: var(--color-light-blue);
  cursor: pointer;
}

.input {
  border: solid 1px #1699a0;
  text-align: center;
  font-size: 18px;
  height: 50px;
  width: 300px;
}

.textSmall {
  font-size: 0.7rem;
  padding-bottom: 1rem;
}

.centerItems .BtnDeact {
  background-color: var(--color-gray);
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border: none;
  font-size: 1rem;
  vertical-align: -0.05rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-top: 2.7rem; 
  text-transform: uppercase;
  font-weight: 700;
}

.centerItems .BtnAct {
  background-color: var(--color-active);
  /* color: #ffffff;
  text-align: center;
  border-radius: 8px;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border: none;
  font-size: 1rem;
  vertical-align: -0.05rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-top: 2.7rem; */
}
