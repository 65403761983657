.sidebarOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: flex-start;
}

.sidebar {
  align-items: flex-start;
  background: #fff;
  -webkit-box-shadow: 3px 0px 12px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 0px 12px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 0px 12px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
}

.sidebar .sidebarItem {
  color: #15193b;
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
}

.sidebar .sidebarItem:first-child {
  margin: 0 auto 3rem auto;
}

.font900 {
  font-weight: 900;
  color: #15193b;
}

.font700 {
  font-weight: 700;
}

.font300 {
  font-weight: 300;
  color: #29afca;
}

.sidebar .sidebarItem:nth-child(4) {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.sidebarItem .btn {
  background-color: var(--color-active);
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  padding: 0.5rem 1.5rem;
  border: none;
  font-size: 1rem;
}

.sidebarItem .btn:hover {
  background: var(--color-hover);
}

.sidebarItem .btn:after {
  content: "DOWNLOAD";
}
.sidebarItem .btn:hover::after {
  content: "TESTE AGORA";
  background-color: var(--color-hover);
}

.sidebarItem .seperator {
  background-color: #1699a0;
  height: 0.15rem;
  width: 16rem;
}

.sidebarItem .selects {
  cursor: pointer;
  color: var(--color-active);
  border-color: var(--color-active);
  border-radius: 4px;
  border: solid 1px;
  font-size: 1rem;
  text-align-last: center;
  padding: 0.3rem 0.5rem;
}

.small {
  color: #333338;
  font-size: 0.6rem;
  margin-left: 0.5rem;
  text-align: center;
  color: var(--color-gray);
}

.sidebarOverlay .close {
  color: #1699a0;
  font-size: 2rem;
  margin: 1rem;
  padding: 0.5rem;
}

@media (max-width: 480px) {
  .sidebarOverlay .sidebar {
    max-width: 80%;
  }

  .sidebar .sidebarItem {
    color: #15193b;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0.5rem;
  }

  .sidebar .sidebarItem:first-child {
    margin: 0 auto 2rem auto;
  }

  .sidebarItem .btn {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }

  .sidebar .sidebarItem:nth-child(4) {
    margin: 0;
  }

  .sidebarItem .seperator {
    width: 12rem;
  }

  .sidebarItem .selects {
    font-size: 0.8rem;
  }

  .sidebarOverlay .close {
    font-size: 1.8rem;
    margin: 0.5rem;
  }
}
