.confirmationContainer {
  display: flex;
  padding-bottom: 3rem;
  flex-direction: column;
}

.title {
  font-weight: 700;
  font-size: 1rem;
}

.textCenter {
  text-align: center;
  padding: 0 2rem 4rem 3rem;
}

.descrTitle {
  font-weight: 900;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 2rem;
}

.titleInputBetfair {
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 0.5rem;
}

.paddingTop {
  padding-top: 1.5rem;
}

.paddingTop .inputCheckBox:checked,
.paddingTop .inputCheckBox span {
  color: var(--color-light-blue);
}

.selects {
  color: var(--color-active);
  border-color: var(--color-active);
  border-radius: 4px;
  border: solid 1px;
  margin-bottom: 2rem;
  padding-top: 0.5rem;
}

.titleInput {
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
}

.input {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.5rem;
  font-size: 1rem;
  border: solid 1px var(--color-active);
  background-color: var(--color-pale);
}
.row {
  display: flex;
  align-items: flex-start;
}

.leftCell {
  width: 50%;
  padding: 0 2rem;
  text-align: left;
}

.rightCell {
  width: 50%;
  padding-left: 2rem;
  padding-right: 2rem;
}

.centerItems {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 1rem;
}

.alignRight {
  float: right;
}

.totalSize {
  font-size: 1.3rem;
}

.formCell {
  background-color: white;
  padding-left: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  margin-left: 0rem;
  margin-right: 2rem;
}

.newForm {
  padding-top: 1rem;
  font-weight: bold;
}

.emptySpace {
  padding-top: 3rem;
}

.continueBtn {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  width: fit-content;
}

.continueBtn .contBtnAct {
  background-color: var(--color-active);
  color: var(--color-background);
  text-align: center;
  border-radius: 4px;
  border: none;
  font-size: 1rem;
  vertical-align: -0.05rem;
  margin-top: 1rem;
  text-transform: uppercase;
  height: 2rem;
  width: 10rem;
}

.continueBtn .contBtnAct div {
  padding-right: 0.3rem;
  padding-left: 0.3rem;
}

.blueTitle {
  color: var(--color-light-blue);
  padding-bottom: 1rem;
  font-weight: bold;
}

.blueTitlePadding {
  color: #29afca;
  font-weight: bold;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.target .selects span,
.target .selects:disabled {
  color: var(--color-light-blue);
}

.spaceBottom {
  padding-bottom: 1rem;
}

.error {
  color: var(--color-error);
}

.loadingParent {
  position: relative;
  max-height: 1rem;
  max-width: 1rem;
}

.loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingContainer div {
  border: 0.2em solid transparent;
  border-top: 0.2em solid var(--color-background);
  border-bottom: 0.2em solid var(--color-background);
  height: 1rem;
  width: 1rem;
}

.small {
  font-size: 0.6rem;
  padding-top: 0.5rem;
}

@media (max-width: 768px) {
  .row {
    display: flex;
    flex-direction: column;
  }

  .leftCell {
    padding: 0 0 1rem 0;
    width: 100%;
  }

  .leftCell .row {
    display: flex;
    flex-direction: row;
  }

  .formCell {
    margin: 0;
  }

  .rightCell {
    padding: 0;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .leftCell {
    padding: 0 1rem 1rem 1rem;
  }

  .rightCell {
    padding: 0 1rem;
  }
}
