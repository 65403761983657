.logoName {
  color: inherit;
  font-size: inherit;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
}
.paddingLeft {
  padding-left: 0.3em;
}

.paddingRight {
  padding-right: 0.3em;
}
