.paymentContainer {
  display: flex;
  padding-bottom: 3rem;
  flex-direction: column;
}
.textCenter {
  text-align: center;
  padding: 0 2rem 4rem 3rem;
}

.title {
  font-weight: 700;
  font-size: 1rem;
}

.leftCell {
  width: 50%;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
}
.leftCell .selects span,
.leftCell .selects:checked {
  color: var(--color-light-blue);
}

.rightCell {
  width: 50%;
  padding: 0 2rem;
}

.row {
  display: flex;
  align-items: flex-start;
}

.ammount {
  text-align: right;
}

.centerItems {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 1rem;
}

.continueBtn {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  width: fit-content;
}

.continueBtn .BtnAct,
.continueBtn .BtnDeact {
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  border: none;
  font-size: 1rem;
  vertical-align: -0.05rem;
  margin-top: 1rem;
  text-transform: uppercase;
}

.continueBtn .BtnAct {
  background-color: var(--color-active);
}

.continueBtn .BtnDeact {
  background-color: var(--color-gray);
}

@media (max-width: 768px) {
  .row {
    display: flex;
    flex-direction: column;
  }

  .leftCell {
    padding: 0 0 1rem 0;
    width: 100%;
  }

  .leftCell .row {
    display: flex;
    flex-direction: row;
  }

  .formCell {
    margin: 0;
  }

  .rightCell {
    padding: 0;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .leftCell {
    padding: 0 1rem 1rem 1rem;
  }

  .rightCell {
    padding: 0 1rem;
  }
}
