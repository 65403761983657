.cell {
  background-color: var(--color-pale);
  padding-left: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  text-align: left;
}
.rightCellTitle {
  color: #29afca;
  padding-bottom: 2rem;
  font-weight: bold;
}
.alignRight {
  float: right;
}

.totalSize {
  font-size: 1.3rem;
}
.summary {
  padding-top: 1rem;
}
.descrTitle {
  font-weight: 900;
  font-size: 1rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.oldPrice {
  text-decoration: line-through;
}
.newPrice {
  color: var(--color-light-blue);
}

.smallText {
  font-size: 0.6rem;
}
