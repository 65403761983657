.main {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 5rem;
  flex-grow: 1;
}
.font300 {
  font-weight: 300;
  color: var(--color-light-blue);
  font-size: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.fontRed {
  color: var(--color-error);
}

.spaceBottom {
  padding-bottom: 1rem;
}

.spaceTop {
  padding-top: 1em;
}
.textContainer {
  font-size: 0.85rem;
}

.textContainer p {
  font-size: inherit;
  padding-bottom: 0.6em;
}
