.footer {
  display: flex;
  padding-top: 5rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
  justify-content: space-between;
  text-align: left;
}

.footerLeft {
  max-width: 35%;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  order: 2;
}

.footerLeft p {
  font-size: 0.7rem;
}

.font900 {
  font-weight: 900;
}

.font700 {
  font-weight: 700;
  color: var(--color-dark-blue);
  padding-bottom: 1rem;
  text-transform: uppercase;
}

.font300 {
  font-weight: 300;
}

.footerRight {
  display: flex;
  align-items: flex-start;
  order: 1;
}

.columnRight {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  text-align: left;
  align-items: flex-start;
  min-width: 8rem;
}

.columnItems {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.footerRight .bottomSpace {
  padding-bottom: 1rem;
  font-size: 1rem;
}

.paymentLogos {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
}

.logo {
  padding-top: 0.5rem;
  place-self: flex-start;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
  }

  .footerLeft {
    max-width: none;
    padding: 0;
  }

  .footerRight {
    flex-direction: column;
    padding-bottom: 1rem;
  }

  .footerRight .columnRight {
    padding: 1rem 0 0 0;
  }

  .columnRight .columnItems {
    flex-direction: row;
  }

  .columnRight .columnItems a,
  .columnRight .columnItems button {
    padding-right: 1rem;
  }
}

@media (max-width: 480px) {
  .columnRight .columnItems a,
  .columnRight .columnItems button {
    font-size: 0.8rem;
  }

  .font700 {
    padding-bottom: 0.5rem;
  }

  .footerRight .bottomSpace {
    padding-bottom: 0.5rem;
  }
}
