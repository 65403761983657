@import url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/css/flag-icon.min.css");

.header {
  align-items: flex-start;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 1rem 1rem 0.8rem 1rem;
  background-color: #ffffff;
  z-index: 2;
  display: flex;
  flex-direction: row;
}

.shadowBottom {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.headerLogo {
  padding-left: 0.5rem;
  align-self: center;
}

.headerLogo .containerDesktop {
  display: flex;
}
.headerLogo .containerMobile {
  display: none;
}

.headerLogo .containerDesktop .imgLogo {
  width: 250px;
}
.headerLogo .containerMobile .imgLogo {
  width: 47px;
  height: 47px;
}

.headerItem {
  padding-right: 1rem;
}

.headerItem:first-child {
  display: none;
}

.headerItem .sidebarBtn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.5rem 0;
  padding: 0.5rem;
  width: calc(24px + 1rem);
}

.sidebarBtn div {
  background-color: var(--color-text);
  height: 4px;
  margin-bottom: 4px;
  width: 100%;
}

.sidebarBtn:focus div,
.sidebarBtn:hover div {
  background-color: var(--color-active);
}

.sidebarBtn .bracket__3 {
  margin: 0;
}

.headerItem:nth-child(2) {
  padding: 0.5rem 0;
}

.headerItem:nth-child(3) {
  margin-left: auto;
}

.headerItem:nth-child(5) {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.headerItem:last-child {
  padding-right: 0;
}

.headerItem a {
  padding: 0.5rem 1rem;
}

.headerItem .headerRight .btnHead {
  font-size: 1rem;
  text-align: center;
  padding: 0.5rem 1.5rem;
}

.headerItem .btn {
  background-color: var(--color-active);
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  padding: 0.3rem 1.5rem;
  border: none;
  font-size: 1rem;
  width: 10rem;
  height: 2rem;
}

.headerItem .btn:hover {
  background: var(--color-hover);
}

.header .headerItem .btn .tryNow,
.header .headerItem .btn .download {
  color: inherit;
  font-size: 1rem;
}

.header .headerItem .btn:hover .download {
  font-size: 0;
}

.header .headerItem .btn:not(:hover) .tryNow {
  font-size: 0;
}

.headerItem .selects {
  cursor: pointer;
  color: var(--color-active);
  border-color: var(--color-active);
  border-radius: 4px;
  border: solid 1px;
  font-size: 1rem;
  text-align-last: center;
  padding: 0.3rem 0.5rem;
}

.small {
  font-size: 0.6rem;
  margin-top: 0.15rem;
  text-align: center;
  color: var(--color-gray);
}

.font900 {
  font-weight: 900;
  color: #15193b;
}

.font700 {
  font-weight: 700;
}

.font300 {
  font-weight: 300;
  color: #29afca;
}

.headerRight .active {
  text-decoration-line: underline;
  text-decoration-color: var(--color-light-blue);
  color: var(--color-light-blue);
}

@media (max-width: 1200px) {
  .headerItem {
    padding-right: 0.5rem;
  }

  .headerItem:nth-child(2) {
    padding: 0;
  }

  .headerItem a,
  .headerItem .btn,
  .headerItem .selects {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }

  .headerLogo .containerDesktop .imgLogo {
    width: 150px;
  }
}

@media (max-width: 768px) {
  .headerItem {
    display: none;
    padding-right: 0.5rem;
  }

  .headerItem:first-child {
    display: flex;
  }

  .headerItem:nth-child(2) {
    display: flex;
    padding: 0.5rem 0;
  }

  .headerItem:nth-child(5) {
    display: flex;
    margin-left: auto;
  }

  .headerItem a {
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
  }

  .small {
    margin-top: 0.05rem;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 0.5rem 0.5rem 0.3rem 0.5rem;
  }

  .headerItem {
    padding: 0;
  }

  .headerItem .sidebarBtn {
    margin: 0.25rem 0;
  }

  .headerItem a {
    padding: 0.5rem;
  }

  .font900,
  .font700,
  .font300 {
    font-size: 0.8rem;
  }

  .headerItem .btn {
    font-size: 0.8rem;
    padding: 0.5rem 0.75rem;
  }

  .headerLogo .containerDesktop {
    display: none;
  }
  .headerLogo .containerMobile {
    display: flex;
  }
}
