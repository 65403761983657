.homePage {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 6rem;
  flex-grow: 1;
}

.containerInicial {
  display: flex;
  align-items: center;
  padding: 0 2rem 3rem 2rem;
  width: 100%;
}

.containerInicial .leftCell {
  display: flex;
  flex-direction: column;
  width: 35%;
}

.containerInicial .rightCell {
  display: flex;
  width: 65%;
}

.containerInicial .rightCell img {
  height: auto;
  width: 100%;
}

.font900 {
  font-weight: 900;
}

.font700 {
  font-weight: 700;
}
.font300 {
  font-weight: 300;
}

.img.w100 {
  width: 100%;
}

.containerSecond {
  display: flex;
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 3rem;
  flex-direction: column;
}

.containerSecond .rightCell {
  width: 50%;
  padding-left: 1rem;
}

.containerSecond .leftCell {
  width: 50%;
  padding-right: 1rem;
}
.textCenter {
  text-align: center;
  padding: 0 2rem 3rem 2rem;
}

.containerSecond .title {
  font-weight: 900;
  color: var(--color-dark-blue);
  font-size: 1.5rem;
}

.containerSecond .line {
  display: flex;
  align-items: flex-start;
}

.smartTrading {
  font-weight: 900;
  color: var(--color-dark-blue);
}

.pro {
  color: var(--color-dark-blue);
}

.containerThird {
  display: flex;
  padding-bottom: 4rem;
}

.containerThird .cell {
  background-color: var(--color-dark-blue);
  color: var(--color-background);
  margin: 0 0.5rem;
  width: 33%;
  padding: 1rem;
}

.containerThird .cell .text {
  color: inherit;
  font-weight: 300;
}

.containerThird .cell .title {
  color: inherit;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: 900;
}

.containerFourth {
  display: flex;
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 2rem;
  flex-direction: column;
}

.containerFourth .cell {
  margin: 0 1rem;
}

.containerFourth .title {
  font-weight: 900;
  font-size: 1.5rem;
}

.containerFourth .row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.containerFourth .row .cell {
  display: flex;
  justify-content: center;
}

.containerFourth .cell img {
  height: auto;
  width: 60%;
}

.containerFifth {
  display: flex;
  flex-direction: column;
  padding: 0 2rem 2rem 2rem;
}

.containerFifth .swiperContainer {
  max-width: 100%;
  align-self: center;
}

.containerFifth .slide {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.containerFifth .swiper-slide {
  width: 60%;
}

.containerFifth .cell {
  padding-top: 2rem;
  align-self: center;
}

.containerFifth .cell {
  align-self: center;
  padding-top: 2rem;
}
.containerFifth .cell .btn {
  background-color: var(--color-active);
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  padding: 0.3rem 1.5rem;
  border: none;
  font-size: 1rem;
  width: 10rem;
  height: 2rem;
}
.containerFifth .cell .btn:hover {
  background: var(--color-hover);
}

.containerFifth .cell .btn .tryNow,
.containerFifth .cell .btn .download {
  color: inherit;
  font-size: 1rem;
}

.containerFifth .cell .btn:hover .download {
  font-size: 0;
}

.containerFifth .cell .btn:not(:hover) .tryNow {
  font-size: 0;
}

.containerSixth {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 2rem 6rem 1.5rem;
}

.containerSixth .cellTop {
  max-width: 700px;
  text-align: center;
  margin: auto;
  padding: 0 2rem 3rem 2rem;
  font-size: 1.5rem;
}

.containerSixth .leftCell {
  width: 50%;
  padding-right: 1rem;
}

.containerSixth .rightCell {
  width: 50%;
  padding-left: 1rem;
}

.containerSixth .row {
  display: flex;
  align-items: flex-start;
}

.containerSeventh {
  display: flex;
  flex-direction: column;
  padding: 0 2rem 2rem 2rem;
}

.containerSeventh .row {
  display: flex;
  align-items: flex-start;
}

.containerSeventh .swiperContainer {
  max-width: 100%;
  align-self: center;
}

.containerSeventh .slide {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.swiperContainer .slide {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.swiperContainer .text {
  padding: 2rem 0rem 1rem 0;
}

.swiperContainer .textOverview {
  font-weight: 900;
  font-size: 1.5rem;
}

.swiperContainer img {
  height: auto;
  width: 100%;
}

.containerEighth {
  display: flex;
  margin-bottom: 3rem;
  flex-direction: column;
}
.containerEighth .title {
  font-size: 1.5rem;
  font-weight: 700;
}
.containerEighth .text {
  font-weight: 700;
}
.bottomSpace {
  padding-bottom: 1rem;
}

.containerEighth .small {
  font-size: 0.8rem;
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  padding: 2rem;
  color: var(--color-gray);
}

.small {
  font-size: 0.8rem;
  text-align: center;
  color: var(--color-gray);
}

.double {
  font-size: 1.5rem;
}

.proBlue {
  font-weight: 300;
  color: var(--color-light-blue);
}

.wagertool {
  text-align: right;
  font-size: 0.45rem;
  padding-right: 20rem;
}

.containerTestimonials {
  text-align: center;
  padding: 0 2rem 3rem 2rem;
}

.containerTestimonials .title {
  font-weight: 700;
  font-size: 1.3rem;
  padding-top: 3rem;
  padding-bottom: 1rem;
}

.containerTestimonials .text {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 768px) {
  .containerInicial {
    align-items: center;
    flex-direction: column;
  }

  .containerInicial .leftCell {
    width: 100%;
  }

  .containerInicial .rightCell {
    margin-top: 1rem;
    width: 100%;
  }

  .containerSecond {
    display: flex;
    flex-direction: column;
    max-width: none;
    padding: 0 2rem 2rem 2rem;
  }

  .containerSecond .rightCell {
    width: 100%;
    padding-left: 0;
  }

  .containerSecond .leftCell {
    margin-bottom: 0.5rem;
    width: 100%;
    padding-right: 0;
  }

  .containerSecond .line {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .containerThird {
    flex-direction: column;
    padding: 0 2rem 3rem 2rem;
  }

  .containerThird .cell {
    margin: 0.5rem 0 0 0;
    width: 100%;
    padding: 1rem;
  }

  .containerThird .cell:first-child {
    margin: 0;
  }

  .containerFourth {
    display: flex;
    max-width: none;
    margin: 0 auto;
    padding: 0 2rem 1rem 2rem;
    flex-direction: column;
  }

  .containerFourth .row {
    flex-direction: column;
  }

  .containerFourth .cell {
    margin: 1rem 0 0 0;
    width: 100%;
  }

  .containerFourth .cell img {
    height: auto;
    width: 30%;
  }

  .containerFourth .cell:first-child {
    margin: 0;
  }

  .swiperContainer span {
    font-size: 0.8rem;
    padding-right: 0.5rem;
  }

  .containerSixth {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 2rem 3rem 2rem;
  }

  .containerSixth .leftCell {
    padding: 0;
    width: 100%;
  }

  .containerSixth .rightCell {
    padding: 0;
    width: 100%;
  }

  .containerSixth .row {
    flex-direction: column;
  }

  .containerSwiper {
    padding-bottom: 3rem;
  }

  .wagertool {
    padding-right: 10rem;
  }
}

@media (max-width: 480px) {
  .containerSecond {
    padding: 0 1rem 2rem 1rem;
  }

  .containerSecond .leftCell {
    margin-bottom: 0.5rem;
  }

  .containerThird {
    padding: 0 1rem 2rem 1rem;
  }

  .containerThird .cell {
    margin: 0.5rem 0 0 0;
    min-height: 150px;
    padding: 1rem;
  }

  .containerFourth {
    padding: 0 1rem 0.5rem 1rem;
  }

  .containerFourth .cell {
    margin: 1rem 0 0 0;
  }

  .containerFourth .cell:first-child {
    margin: 0;
  }

  .containerFifth {
    padding: 0 1rem 2rem 1rem;
  }

  .swiperContainer span {
    display: flex;
    padding-bottom: 0.5rem;
  }

  .containerSixth {
    padding: 0 1rem 2rem 1rem;
  }

  .containerSixth .cellTop {
    font-size: 0.8rem;
    padding: 0 1rem 2rem 1rem;
  }

  .containerSeventh {
    padding: 0 1rem 2rem 1rem;
  }

  .containerEighth .small {
    margin: 0 auto;
    padding: 1rem;
  }

  .wagertool {
    padding-right: 8rem;
  }

  .textCenter {
    padding: 0 1rem 2rem 1rem;
  }

  .containerTestimonials {
    padding: 0 1rem 2rem 1rem;
  }

  .containerTestimonials .title {
    padding-top: 2rem;
  }

  .containerTestimonials .text {
    max-width: none;
  }
}

@media (max-width: 410px) {
  .wagertool {
    padding-right: 1rem;
  }
}
