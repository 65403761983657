:root {
  --color-black: #15193b;
  --color-background: #ffffff;
  --color-light-blue: #29afca;
  --color-dark-blue: #23214b;
  --color-gray: #7e7e89;
  --color-text:  #333338;
  --color-pale: #e6e4e4;
  --color-hover:#1d7780;
  --color-active:#1699a0;
  --color-error:#ed4337;
}