.main {
  margin: auto;
  margin-top: 5rem;
  /* display: flex; */
  max-width: 1200px;
  text-align: left;
  flex-grow: 1;
}

.column {
  display: flex;
  flex-direction: column;
}

.rowTop {
  display: flex;
  align-items: flex-start;
}

.title {
  font-weight: 300;
  color: var(--color-light-blue);
  padding: 2rem;
  margin: 0 auto;
}

.sticky {
  position: sticky;
}

.leftCell {
  height: 100%;
  width: 50%;
  padding: 0 1rem;
}

.rightCell {
  width: 50%;
  padding: 0 1rem;
}

.titleRight {
  font-weight: 700;
  padding-bottom: 0.5rem;
  color: var(--color-light-blue);
}

.rowTop .leftCell .btnGuia:hover {
  text-decoration-line: underline;
  text-decoration-color: var(--color-light-blue);
  color: var(--color-light-blue);
}

.leftCell .selects {
  display: none;
  cursor: pointer;
  color: var(--color-active);
  border-color: var(--color-active);
  border-radius: 4px;
  border: solid 1px;
  font-size: 1rem;
  padding: 0.3rem 0.5rem;
}

.leftCell ol.pills {
  counter-reset: section;
  list-style-type: none;
  flex-direction: column;
  display: flex;
  color: red;
}

.leftCell ol.pills .spaceLeft-1 {
  padding-left: 0;
}

.leftCell ol.pills .spaceLeft-2 {
  padding-left: 1rem;
}

.leftCell ol.pills .spaceTop {
  padding-top: 0.5rem;
}

.leftCell ol.pills li {
  color: var(--color-text);
  display: flex;
  align-items: center;

  padding-bottom: 0.5rem;
}

.leftCell ol.pills .btnGuia {
  display: flex;
  font-size: inherit;
}

.leftCell ol.pills .btnGuia:hover,
.leftCell ol.pills .btnGuia.active {
  text-decoration-line: underline;
  text-decoration-color: var(--color-light-blue);
  color: var(--color-light-blue);
}

.leftCell ol.pills .btnGuia.active {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .title {
    font-size: 2.4rem;
  }

  .rowTop {
    flex-direction: column;
  }

  .leftCell {
    padding: 0 2rem;
    width: 100%;
  }

  .leftCell .selects {
    display: block;
    margin-bottom: 2rem;
  }

  .leftCell .selects option {
    flex-wrap: wrap;
  }

  .leftCell ol.pills {
    display: none;
  }

  .rightCell {
    padding: 0 2rem;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 2rem;
  }

  .leftCell {
    padding: 0 1rem;
  }

  .leftCell .selects {
    font-size: 0.8rem;
  }

  .rightCell {
    padding: 0 1rem;
  }
}
