h1 {
  font-size: 3rem;
}

h2 {
  font-size: 3rem;
  text-align: center;
  margin: 0;
  line-height: 1.1em;
}

h3 {
  font-size: 1.75rem;
  text-align: left;
  color: var(--color-light-blue);
  margin: 0 0 0.5em 0;
  line-height: 1.2em;
}

h4 {
  font-size: 1.5rem;
  font-weight: normal;
}

h5 {
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;
  color: #29afca;
  margin: 1rem 0 0 0;
  padding: 0.3rem 0;
}

h6 {
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;
  margin: 1rem 0 0.5rem 0;
}

p {
  font-size: 1rem;
  line-height: 1.4em;
  margin: 0;
  word-wrap: break-word;
  color: var(--color-text);
}

p.double {
  font-size: 1.5rem;
}

p.half {
  font-size: 0.7rem;
  text-align: center;
}

a,
a:hover,
a:focus,
a:active,
a:visited {
  text-decoration: none;
  color: inherit;
}

.isLink,
p .isLink {
  color: var(--color-active);
  text-decoration: underline;
}
.isLink:hover,
.isLink:focus,
.isLink:active,
p .isLink:hover,
p .isLink:focus,
p .isLink:active {
  color: var(--color-hover);
}

select {
  font-family: "Lato", sans-serif;
  font-size: inherit;
}

html {
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.font900 {
  font-weight: 900;
}

.font700 {
  font-weight: 700;
}

.font300 {
  font-weight: 300;
}

button {
  font-family: inherit;
}

@media (min-width: 1600px) {
  html {
    font-size: 20px;
    /* orignal was: font-size: 16px; */
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 19px;
    /* orignal was: font-size: 16px; */
  }
}

@media (min-width: 992px) {
  html {
    font-size: 18px;
    /* orignal was: font-size: 15px; */
  }
}

@media (min-width: 768px) {
  html {
    font-size: 18px;
    /* orignal was: font-size: 14px; */
  }
}

@media (max-width: 480px) {
  html {
    font-size: 18px;
    /* orignal was: font-size: 12px; */
  }
}

@media (min-width: 480px) {
  html {
    font-size: 18px;
    /* orignal was: font-size: 13px; */
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
