.swiper-pagination-bullet {
  padding-bottom: 3rem;
  font-weight: 100;
  cursor: pointer;
  padding-left: unset;
  padding-right: 2rem;
  font-weight: bold;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  text-decoration-line: underline;
  text-decoration-color: var(--color-light-blue);
  color: var(--color-light-blue);
}
