.main {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 5rem;
  flex-grow: 1;
}

.textCenter {
  text-align: center;
  padding: 0 0 3rem 0;
}

.titleCenter {
  font-size: 1.7rem;
  padding: 2rem 2rem 0 2rem;
}

.title {
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 2rem;
}

.container {
  text-align: center;
  padding: 3rem 2rem 3rem 2rem;
}

@media (max-width: 768px) {
  .container {
    padding: 2rem 1rem 2rem 1rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 2rem 0;
  }
}
