.buttonTab {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.cell {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 4rem;
  width: 100%;
}

.cell .btnOS {
  text-align: left;
  width: calc(100% / 4 - 0.5rem);
}

.buttonTab .borderBottom {
  display: flex;
  width: 100%;
  height: 0.2rem;
  background-color: var(--color-gray);
  border-radius: 0.4rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.buttonTab .borderBottom.noColor {
  background-color: unset;
}

.buttonTab .hoverSpan {
  background-color: var(--color-hover);
}
.buttonTab .activeSpan {
  background-color: var(--color-light-blue);
}
.buttonTab .text {
  padding-bottom: 0.3rem;
  font-weight: 700;
}

.buttonTab .hoverText {
  color: var(--color-hover);
}

.buttonTab .active {
  text-decoration-line: underline;
  text-decoration-color: var(--color-light-blue);
  color: var(--color-light-blue);
}

@media (max-width: 768px) {
  .cell {
    padding: 0 2rem;
  }

  .cell .btnOS p {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .cell {
    padding: 0 1rem;
  }
}
