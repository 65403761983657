.main {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 5rem;
  flex-grow: 1;
}

.column {
  display: flex;
  flex-direction: column;
}

.rowTop {
  display: flex;
  align-items: flex-start;
}

.title {
  font-weight: 300;
  color: var(--color-light-blue);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.leftCell {
  width: 50%;
  padding-right: 1rem;
}

.rightCell {
  width: 50%;
  padding-left: 1rem;
}

.font900 {
  font-weight: 900;
}

.font300 {
  font-weight: 300;
}

.bottomSpace {
  padding-bottom: 3rem;
}

.spaceBottom {
  padding-bottom: 0.5rem;
}

.textTitle {
  padding-top: 1rem;
  font-weight: 700;
  color: var(--color-light-blue);
  padding-bottom: 1rem;
}

.darkBlue {
  color: var(--color-dark-blue);
}
.containerBtns {
  display: flex;
  padding-top: 1rem;
  display: flex;
  justify-content: space-around;
}

.cell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cell .btnOs {
  padding: 3rem 2rem;
  background-color: white;
  color: black;
  border: 1px solid var(--color-light-blue);
  border-radius: 4px;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

.cell .btnOsActive {
  background-color: #23214b;
  color: #ffffff;
}

.btnDownload {
  text-align: center;
  align-items: center;
  margin: 3rem;
  display: flex;
  flex-direction: column;
}

.btnDownload .btn {
  background-color: var(--color-active);
  color: var(--color-background);
  text-align: center;
  border-radius: 4px;
  height: 2rem;
  width: 10rem;
  border: none;
  font-size: 1rem;
}
.btnDownload .btn.btnDisable {
  background-color: var(--color-gray);
  color: var(--color-background);
  text-align: center;
  border-radius: 4px;
  height: 2rem;
  width: 10rem;
  border: none;
  font-size: 1rem;
}
.smallText {
  font-size: 0.7rem;
  color: var(--color-gray);
  padding-right: 3.5rem;
  padding-left: 3.5rem;
  padding-top: 0.5rem;
}
ul li {
  list-style-type: circle;
  padding-bottom: 0.5rem;
  line-height: 1.2em;
}

.betfair {
  font-weight: 700;
  color: #15193b;
}

.conta {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.icon {
  height: 1rem;
  width: 1rem;
  background-position: center;
  padding-right: 2.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  filter: invert(100%) sepia(6%) saturate(0%) hue-rotate(313deg)
    brightness(105%) contrast(106%);
}

.buttonTab .borderBottom {
  display: flex;
  width: 100%;
  height: 0.2rem;
  background-color: var(--color-gray);
  border-radius: 0.4rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  min-width: 8rem;
}

.buttonTab .text {
  padding-bottom: 0.3rem;
  font-weight: 700;
}

.btnOS.active .text {
  text-decoration-line: underline;
  text-decoration-color: var(--color-light-blue);
  color: var(--color-light-blue);
}

.btnOS.active .buttonTab .borderBottom {
  background-color: var(--color-light-blue);
}

.icon {
  height: 1rem;
  width: 1rem;
  background-position: center;
  padding-right: 2.5rem;
  background-size: contain;
  background-repeat: no-repeat;
}
.icon.windows {
  background-image: url("../../src/icons/windows-logo.svg");
}
.icon.mac {
  background-image: url("../../src/icons/apple-logo.svg");
}
.icon.linux {
  background-image: url("../../src/icons/linux-logo.svg");
}
