@import url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/css/flag-icon.min.css);
h1 {
  font-size: 3rem;
}

h2 {
  font-size: 3rem;
  text-align: center;
  margin: 0;
  line-height: 1.1em;
}

h3 {
  font-size: 1.75rem;
  text-align: left;
  color: var(--color-light-blue);
  margin: 0 0 0.5em 0;
  line-height: 1.2em;
}

h4 {
  font-size: 1.5rem;
  font-weight: normal;
}

h5 {
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;
  color: #29afca;
  margin: 1rem 0 0 0;
  padding: 0.3rem 0;
}

h6 {
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;
  margin: 1rem 0 0.5rem 0;
}

p {
  font-size: 1rem;
  line-height: 1.4em;
  margin: 0;
  word-wrap: break-word;
  color: var(--color-text);
}

p.double {
  font-size: 1.5rem;
}

p.half {
  font-size: 0.7rem;
  text-align: center;
}

a,
a:hover,
a:focus,
a:active,
a:visited {
  text-decoration: none;
  color: inherit;
}

.isLink,
p .isLink {
  color: var(--color-active);
  text-decoration: underline;
}
.isLink:hover,
.isLink:focus,
.isLink:active,
p .isLink:hover,
p .isLink:focus,
p .isLink:active {
  color: var(--color-hover);
}

select {
  font-family: "Lato", sans-serif;
  font-size: inherit;
}

html {
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.font900 {
  font-weight: 900;
}

.font700 {
  font-weight: 700;
}

.font300 {
  font-weight: 300;
}

button {
  font-family: inherit;
}

@media (min-width: 1600px) {
  html {
    font-size: 20px;
    /* orignal was: font-size: 16px; */
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 19px;
    /* orignal was: font-size: 16px; */
  }
}

@media (min-width: 992px) {
  html {
    font-size: 18px;
    /* orignal was: font-size: 15px; */
  }
}

@media (min-width: 768px) {
  html {
    font-size: 18px;
    /* orignal was: font-size: 14px; */
  }
}

@media (max-width: 480px) {
  html {
    font-size: 18px;
    /* orignal was: font-size: 12px; */
  }
}

@media (min-width: 480px) {
  html {
    font-size: 18px;
    /* orignal was: font-size: 13px; */
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

:root {
  --color-black: #15193b;
  --color-background: #ffffff;
  --color-light-blue: #29afca;
  --color-dark-blue: #23214b;
  --color-gray: #7e7e89;
  --color-text:  #333338;
  --color-pale: #e6e4e4;
  --color-hover:#1d7780;
  --color-active:#1699a0;
  --color-error:#ed4337;
}
* {
  box-sizing: border-box;
}

html {
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

select {
  font-family: "Lato", sans-serif;
  font-size: inherit;
}

body {
  height: 100%;
  min-width: 320px;
  margin: 0;
  padding: 0;
  background: var(--color-background);
  color: var(--color-text);
}

.body {
  color: var(--color-main-text);
  background-color: var(--color-main-background);
}

.d-none {
  display: none !important;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: inherit;
  opacity: 0.4;
  padding-left: 5px;
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: inherit;
  opacity: 0.4;
  padding-left: 5px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: inherit;
  opacity: 0.4;
  padding-left: 5px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: inherit;
  opacity: 0.4;
  padding-left: 5px;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: inherit;
  opacity: 0.4;
  padding-left: 5px;
}

input[type="number"] {
  -webkit-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

blockquote,
body,
code,
dd,
div,
dl,
dt,
fieldset,
form,
h1,
h2,
h4,
h5,
h6,
input,
legend,
li,
ol,
pre,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
  line-height: 1;
}

li {
  list-style: none;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/****** LOADING elements - START ******/
.loading-gradient {
  width: 100%;
  background-size: 200% 100% !important;
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.05) 0%,
    rgba(0, 0, 0, 0.25) 50%,
    rgba(0, 0, 0, 0.05) 100%
  ) !important;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-name: GradientSlide;
          animation-name: GradientSlide;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@-webkit-keyframes GradientSlide {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 200% 0%;
  }
}

@keyframes GradientSlide {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 200% 0%;
  }
}

/****** LOADING elements - END ******/

/****** LOADER circle - START ******/
.loader-circle {
  /* set font-size on parent element to control the size of this loader circle */
  border: 0.75em solid transparent;
  border-top: 0.75em solid var(--color-active);
  border-bottom: 0.75em solid var(--color-active);
  border-radius: 50%;
  width: 5em;
  height: 5em;
  margin: 1em;
  -webkit-animation: spin 1.5s linear infinite;
          animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/****** LOADER circle - END ******/

/****** MOBILE TABLET display none - START ******/
@media screen and (min-width: 1024px) {
  .lg-none {
    display: none !important;
  }
}

@media screen and (min-width: 475px) and (max-width: 1023px) {
  .md-none {
    display: none !important;
  }

  .lg-block {
    display: none !important;
  }
}

@media screen and (max-width: 474px) {
  .xs-none {
    display: none !important;
  }

  .lg-block {
    display: none !important;
  }
}

/****** MOBILE TABLET display none - END ******/

@supports (-webkit-overflow-scrolling: touch) {
  /** prevent zoom in on inputs on mobile devices **/
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

/****** Obfuscate email - START ******/
.emailHere {
  font-weight: bold;
}
.emailHere::before {
  content: attr(data-emailname);
}
.emailHere::after {
  content: attr(data-emaildomain);
}
.emailHere .hiden {
  display: none;
}
/****** Obfuscate email - END ******/


.main{

}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.contact-page_main__3FqQW {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 5rem;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
.contact-page_font300__G8FIx {
  font-weight: 300;
  color: #29afca;
  font-size: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.contact-page_spaceBottom__1lkb9 {
  padding-bottom: 1rem;
}

.home-page_homePage__1RXrX {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 6rem;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.home-page_containerInicial__2orMc {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 2rem 3rem 2rem;
  width: 100%;
}

.home-page_containerInicial__2orMc .home-page_leftCell__PG2mu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 35%;
}

.home-page_containerInicial__2orMc .home-page_rightCell__Wij9Z {
  display: -webkit-flex;
  display: flex;
  width: 65%;
}

.home-page_containerInicial__2orMc .home-page_rightCell__Wij9Z img {
  height: auto;
  width: 100%;
}

.home-page_font900__3ZrWw {
  font-weight: 900;
}

.home-page_font700__18nRW {
  font-weight: 700;
}
.home-page_font300__qqJsX {
  font-weight: 300;
}

.home-page_img__ClqJ-.home-page_w100__38B4M {
  width: 100%;
}

.home-page_containerSecond__316zg {
  display: -webkit-flex;
  display: flex;
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 3rem;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.home-page_containerSecond__316zg .home-page_rightCell__Wij9Z {
  width: 50%;
  padding-left: 1rem;
}

.home-page_containerSecond__316zg .home-page_leftCell__PG2mu {
  width: 50%;
  padding-right: 1rem;
}
.home-page_textCenter__3E2ny {
  text-align: center;
  padding: 0 2rem 3rem 2rem;
}

.home-page_containerSecond__316zg .home-page_title__1eTQC {
  font-weight: 900;
  color: var(--color-dark-blue);
  font-size: 1.5rem;
}

.home-page_containerSecond__316zg .home-page_line__FNuVT {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.home-page_smartTrading__2ri7P {
  font-weight: 900;
  color: var(--color-dark-blue);
}

.home-page_pro__22w9e {
  color: var(--color-dark-blue);
}

.home-page_containerThird__18heY {
  display: -webkit-flex;
  display: flex;
  padding-bottom: 4rem;
}

.home-page_containerThird__18heY .home-page_cell__29Q5Z {
  background-color: var(--color-dark-blue);
  color: var(--color-background);
  margin: 0 0.5rem;
  width: 33%;
  padding: 1rem;
}

.home-page_containerThird__18heY .home-page_cell__29Q5Z .home-page_text__1RphL {
  color: inherit;
  font-weight: 300;
}

.home-page_containerThird__18heY .home-page_cell__29Q5Z .home-page_title__1eTQC {
  color: inherit;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: 900;
}

.home-page_containerFourth__1NhGz {
  display: -webkit-flex;
  display: flex;
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 2rem;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.home-page_containerFourth__1NhGz .home-page_cell__29Q5Z {
  margin: 0 1rem;
}

.home-page_containerFourth__1NhGz .home-page_title__1eTQC {
  font-weight: 900;
  font-size: 1.5rem;
}

.home-page_containerFourth__1NhGz .home-page_row__1zAzB {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.home-page_containerFourth__1NhGz .home-page_row__1zAzB .home-page_cell__29Q5Z {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.home-page_containerFourth__1NhGz .home-page_cell__29Q5Z img {
  height: auto;
  width: 60%;
}

.home-page_containerFifth__-Cd9D {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0 2rem 2rem 2rem;
}

.home-page_containerFifth__-Cd9D .home-page_swiperContainer__1aTWY {
  max-width: 100%;
  -webkit-align-self: center;
          align-self: center;
}

.home-page_containerFifth__-Cd9D .home-page_slide__1znKp {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
}

.home-page_containerFifth__-Cd9D .home-page_swiper-slide__1cTn_ {
  width: 60%;
}

.home-page_containerFifth__-Cd9D .home-page_cell__29Q5Z {
  padding-top: 2rem;
  -webkit-align-self: center;
          align-self: center;
}

.home-page_containerFifth__-Cd9D .home-page_cell__29Q5Z {
  -webkit-align-self: center;
          align-self: center;
  padding-top: 2rem;
}
.home-page_containerFifth__-Cd9D .home-page_cell__29Q5Z .home-page_btn__3QvPP {
  background-color: var(--color-active);
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  padding: 0.3rem 1.5rem;
  border: none;
  font-size: 1rem;
  width: 10rem;
  height: 2rem;
}
.home-page_containerFifth__-Cd9D .home-page_cell__29Q5Z .home-page_btn__3QvPP:hover {
  background: var(--color-hover);
}

.home-page_containerFifth__-Cd9D .home-page_cell__29Q5Z .home-page_btn__3QvPP .home-page_tryNow__1nYRd,
.home-page_containerFifth__-Cd9D .home-page_cell__29Q5Z .home-page_btn__3QvPP .home-page_download__2Z1kn {
  color: inherit;
  font-size: 1rem;
}

.home-page_containerFifth__-Cd9D .home-page_cell__29Q5Z .home-page_btn__3QvPP:hover .home-page_download__2Z1kn {
  font-size: 0;
}

.home-page_containerFifth__-Cd9D .home-page_cell__29Q5Z .home-page_btn__3QvPP:not(:hover) .home-page_tryNow__1nYRd {
  font-size: 0;
}

.home-page_containerSixth__fXnga {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 auto;
  padding: 0 2rem 6rem 1.5rem;
}

.home-page_containerSixth__fXnga .home-page_cellTop__26KtL {
  max-width: 700px;
  text-align: center;
  margin: auto;
  padding: 0 2rem 3rem 2rem;
  font-size: 1.5rem;
}

.home-page_containerSixth__fXnga .home-page_leftCell__PG2mu {
  width: 50%;
  padding-right: 1rem;
}

.home-page_containerSixth__fXnga .home-page_rightCell__Wij9Z {
  width: 50%;
  padding-left: 1rem;
}

.home-page_containerSixth__fXnga .home-page_row__1zAzB {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.home-page_containerSeventh__16BuS {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0 2rem 2rem 2rem;
}

.home-page_containerSeventh__16BuS .home-page_row__1zAzB {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.home-page_containerSeventh__16BuS .home-page_swiperContainer__1aTWY {
  max-width: 100%;
  -webkit-align-self: center;
          align-self: center;
}

.home-page_containerSeventh__16BuS .home-page_slide__1znKp {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
}

.home-page_swiperContainer__1aTWY .home-page_slide__1znKp {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
}

.home-page_swiperContainer__1aTWY .home-page_text__1RphL {
  padding: 2rem 0rem 1rem 0;
}

.home-page_swiperContainer__1aTWY .home-page_textOverview__1DXp2 {
  font-weight: 900;
  font-size: 1.5rem;
}

.home-page_swiperContainer__1aTWY img {
  height: auto;
  width: 100%;
}

.home-page_containerEighth__vOvH2 {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 3rem;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.home-page_containerEighth__vOvH2 .home-page_title__1eTQC {
  font-size: 1.5rem;
  font-weight: 700;
}
.home-page_containerEighth__vOvH2 .home-page_text__1RphL {
  font-weight: 700;
}
.home-page_bottomSpace__2NWlx {
  padding-bottom: 1rem;
}

.home-page_containerEighth__vOvH2 .home-page_small__3P0LO {
  font-size: 0.8rem;
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  padding: 2rem;
  color: var(--color-gray);
}

.home-page_small__3P0LO {
  font-size: 0.8rem;
  text-align: center;
  color: var(--color-gray);
}

.home-page_double__2zi1f {
  font-size: 1.5rem;
}

.home-page_proBlue__1IbpX {
  font-weight: 300;
  color: var(--color-light-blue);
}

.home-page_wagertool__1jpMj {
  text-align: right;
  font-size: 0.45rem;
  padding-right: 20rem;
}

.home-page_containerTestimonials__1ceSE {
  text-align: center;
  padding: 0 2rem 3rem 2rem;
}

.home-page_containerTestimonials__1ceSE .home-page_title__1eTQC {
  font-weight: 700;
  font-size: 1.3rem;
  padding-top: 3rem;
  padding-bottom: 1rem;
}

.home-page_containerTestimonials__1ceSE .home-page_text__1RphL {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 768px) {
  .home-page_containerInicial__2orMc {
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .home-page_containerInicial__2orMc .home-page_leftCell__PG2mu {
    width: 100%;
  }

  .home-page_containerInicial__2orMc .home-page_rightCell__Wij9Z {
    margin-top: 1rem;
    width: 100%;
  }

  .home-page_containerSecond__316zg {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: none;
    padding: 0 2rem 2rem 2rem;
  }

  .home-page_containerSecond__316zg .home-page_rightCell__Wij9Z {
    width: 100%;
    padding-left: 0;
  }

  .home-page_containerSecond__316zg .home-page_leftCell__PG2mu {
    margin-bottom: 0.5rem;
    width: 100%;
    padding-right: 0;
  }

  .home-page_containerSecond__316zg .home-page_line__FNuVT {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }

  .home-page_containerThird__18heY {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0 2rem 3rem 2rem;
  }

  .home-page_containerThird__18heY .home-page_cell__29Q5Z {
    margin: 0.5rem 0 0 0;
    width: 100%;
    padding: 1rem;
  }

  .home-page_containerThird__18heY .home-page_cell__29Q5Z:first-child {
    margin: 0;
  }

  .home-page_containerFourth__1NhGz {
    display: -webkit-flex;
    display: flex;
    max-width: none;
    margin: 0 auto;
    padding: 0 2rem 1rem 2rem;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .home-page_containerFourth__1NhGz .home-page_row__1zAzB {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .home-page_containerFourth__1NhGz .home-page_cell__29Q5Z {
    margin: 1rem 0 0 0;
    width: 100%;
  }

  .home-page_containerFourth__1NhGz .home-page_cell__29Q5Z img {
    height: auto;
    width: 30%;
  }

  .home-page_containerFourth__1NhGz .home-page_cell__29Q5Z:first-child {
    margin: 0;
  }

  .home-page_swiperContainer__1aTWY span {
    font-size: 0.8rem;
    padding-right: 0.5rem;
  }

  .home-page_containerSixth__fXnga {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0 auto;
    padding: 0 2rem 3rem 2rem;
  }

  .home-page_containerSixth__fXnga .home-page_leftCell__PG2mu {
    padding: 0;
    width: 100%;
  }

  .home-page_containerSixth__fXnga .home-page_rightCell__Wij9Z {
    padding: 0;
    width: 100%;
  }

  .home-page_containerSixth__fXnga .home-page_row__1zAzB {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .home-page_containerSwiper__39OOb {
    padding-bottom: 3rem;
  }

  .home-page_wagertool__1jpMj {
    padding-right: 10rem;
  }
}

@media (max-width: 480px) {
  .home-page_containerSecond__316zg {
    padding: 0 1rem 2rem 1rem;
  }

  .home-page_containerSecond__316zg .home-page_leftCell__PG2mu {
    margin-bottom: 0.5rem;
  }

  .home-page_containerThird__18heY {
    padding: 0 1rem 2rem 1rem;
  }

  .home-page_containerThird__18heY .home-page_cell__29Q5Z {
    margin: 0.5rem 0 0 0;
    min-height: 150px;
    padding: 1rem;
  }

  .home-page_containerFourth__1NhGz {
    padding: 0 1rem 0.5rem 1rem;
  }

  .home-page_containerFourth__1NhGz .home-page_cell__29Q5Z {
    margin: 1rem 0 0 0;
  }

  .home-page_containerFourth__1NhGz .home-page_cell__29Q5Z:first-child {
    margin: 0;
  }

  .home-page_containerFifth__-Cd9D {
    padding: 0 1rem 2rem 1rem;
  }

  .home-page_swiperContainer__1aTWY span {
    display: -webkit-flex;
    display: flex;
    padding-bottom: 0.5rem;
  }

  .home-page_containerSixth__fXnga {
    padding: 0 1rem 2rem 1rem;
  }

  .home-page_containerSixth__fXnga .home-page_cellTop__26KtL {
    font-size: 0.8rem;
    padding: 0 1rem 2rem 1rem;
  }

  .home-page_containerSeventh__16BuS {
    padding: 0 1rem 2rem 1rem;
  }

  .home-page_containerEighth__vOvH2 .home-page_small__3P0LO {
    margin: 0 auto;
    padding: 1rem;
  }

  .home-page_wagertool__1jpMj {
    padding-right: 8rem;
  }

  .home-page_textCenter__3E2ny {
    padding: 0 1rem 2rem 1rem;
  }

  .home-page_containerTestimonials__1ceSE {
    padding: 0 1rem 2rem 1rem;
  }

  .home-page_containerTestimonials__1ceSE .home-page_title__1eTQC {
    padding-top: 2rem;
  }

  .home-page_containerTestimonials__1ceSE .home-page_text__1RphL {
    max-width: none;
  }
}

@media (max-width: 410px) {
  .home-page_wagertool__1jpMj {
    padding-right: 1rem;
  }
}

.priceCards_priceCards__1oyEL {
  display: -webkit-flex;
  display: flex;
  padding: 0 2rem 2rem 2rem;
}

.priceCards_priceCards__1oyEL .priceCards_btn__3C0xO {
  background-color: var(--color-active);
  color: var(--color-background);
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  left: 50%;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 50%);
  text-transform: uppercase;
  -webkit-transform: translate(-50%, 50%);
  -moz-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  -o-transform: translate(-50%, 50%);
}
.priceCards_priceCards__1oyEL .priceCards_btn__3C0xO:hover {
  background: var(--color-hover);
}

.priceCards_priceCards__1oyEL .priceCards_cell__-wPgI {
  background-color: var(--color-pale);
  color: var(--color-text);
  margin-right: 0.5rem;
  width: 33%;
  padding: 1.5rem 2rem;
  text-align: left;
  position: relative;
  cursor: pointer;
}

.priceCards_priceCards__1oyEL .priceCards_cell__-wPgI:last-child {
  margin: 0;
}

.priceCards_priceCards__1oyEL .priceCards_cell__-wPgI .priceCards_price__17duF {
  font-size: 1.6rem;
  font-weight: 300;
}

.priceCards_priceCards__1oyEL .priceCards_title__1aXAl {
  font-weight: 700;
  font-size: 1.1rem;
}

.priceCards_priceCards__1oyEL .priceCards_text__zotI3 {
  font-size: 0.8rem;
  padding-bottom: 1.5rem;
  color: var(--color-gray);
}

.priceCards_discount__1q4dl {
  display: -webkit-flex;
  display: flex;
  padding-bottom: 0.5rem;
}

.priceCards_oldPrice__1xmhn {
  text-decoration: line-through;
}
.priceCards_newPrice__fmupV {
  color: var(--color-light-blue);
  padding-left: 0.5rem;
}

.priceCards_home__2M69E {
  content: "Comprar";
}

@media (max-width: 1024px) {
  .priceCards_priceCards__1oyEL .priceCards_cell__-wPgI {
    padding: 1.5rem;
  }

  .priceCards_priceCards__1oyEL .priceCards_cell__-wPgI .priceCards_price__17duF {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .priceCards_priceCards__1oyEL {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0 1rem 1rem 1rem;
  }

  .priceCards_priceCards__1oyEL .priceCards_cell__-wPgI {
    margin: 0 0 2rem 0;
    width: 100%;
  }

  .priceCards_priceCards__1oyEL .priceCards_cell__-wPgI .priceCards_price__17duF {
    font-size: 1.6rem;
  }
}

@media (max-width: 480px) {
  .priceCards_priceCards__1oyEL {
    padding: 0 1rem 1rem 1rem;
  }

  .priceCards_priceCards__1oyEL .priceCards_cell__-wPgI {
    padding: 0.5rem 1rem;
  }

  .priceCards_priceCards__1oyEL .priceCards_cell__-wPgI .priceCards_price__17duF {
    font-size: 1.4rem;
  }
}

.SmartProTrading_logoName__2ykc- {
  color: inherit;
  font-size: inherit;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.SmartProTrading_paddingLeft__2Q28j {
  padding-left: 0.3em;
}

.SmartProTrading_paddingRight__1JpPH {
  padding-right: 0.3em;
}

.swiper-pagination-bullet {
  padding-bottom: 3rem;
  font-weight: 100;
  cursor: pointer;
  padding-left: unset;
  padding-right: 2rem;
  font-weight: bold;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: var(--color-light-blue);
          text-decoration-color: var(--color-light-blue);
  color: var(--color-light-blue);
}

.header_header__3kAwv {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 1rem 1rem 0.8rem 1rem;
  background-color: #ffffff;
  z-index: 2;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.header_shadowBottom__3_UUI {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.header_headerLogo__1ECIY {
  padding-left: 0.5rem;
  -webkit-align-self: center;
          align-self: center;
}

.header_headerLogo__1ECIY .header_containerDesktop__3M-sC {
  display: -webkit-flex;
  display: flex;
}
.header_headerLogo__1ECIY .header_containerMobile__aOcez {
  display: none;
}

.header_headerLogo__1ECIY .header_containerDesktop__3M-sC .header_imgLogo__3ULiY {
  width: 250px;
}
.header_headerLogo__1ECIY .header_containerMobile__aOcez .header_imgLogo__3ULiY {
  width: 47px;
  height: 47px;
}

.header_headerItem__xO8JM {
  padding-right: 1rem;
}

.header_headerItem__xO8JM:first-child {
  display: none;
}

.header_headerItem__xO8JM .header_sidebarBtn__2SsRA {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 0.5rem 0;
  padding: 0.5rem;
  width: calc(24px + 1rem);
}

.header_sidebarBtn__2SsRA div {
  background-color: var(--color-text);
  height: 4px;
  margin-bottom: 4px;
  width: 100%;
}

.header_sidebarBtn__2SsRA:focus div,
.header_sidebarBtn__2SsRA:hover div {
  background-color: var(--color-active);
}

.header_sidebarBtn__2SsRA .header_bracket__3__2IunL {
  margin: 0;
}

.header_headerItem__xO8JM:nth-child(2) {
  padding: 0.5rem 0;
}

.header_headerItem__xO8JM:nth-child(3) {
  margin-left: auto;
}

.header_headerItem__xO8JM:nth-child(5) {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.header_headerItem__xO8JM:last-child {
  padding-right: 0;
}

.header_headerItem__xO8JM a {
  padding: 0.5rem 1rem;
}

.header_headerItem__xO8JM .header_headerRight__3Zp9V .header_btnHead__1AoOq {
  font-size: 1rem;
  text-align: center;
  padding: 0.5rem 1.5rem;
}

.header_headerItem__xO8JM .header_btn__1QZrw {
  background-color: var(--color-active);
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  padding: 0.3rem 1.5rem;
  border: none;
  font-size: 1rem;
  width: 10rem;
  height: 2rem;
}

.header_headerItem__xO8JM .header_btn__1QZrw:hover {
  background: var(--color-hover);
}

.header_header__3kAwv .header_headerItem__xO8JM .header_btn__1QZrw .header_tryNow__104dH,
.header_header__3kAwv .header_headerItem__xO8JM .header_btn__1QZrw .header_download__687W9 {
  color: inherit;
  font-size: 1rem;
}

.header_header__3kAwv .header_headerItem__xO8JM .header_btn__1QZrw:hover .header_download__687W9 {
  font-size: 0;
}

.header_header__3kAwv .header_headerItem__xO8JM .header_btn__1QZrw:not(:hover) .header_tryNow__104dH {
  font-size: 0;
}

.header_headerItem__xO8JM .header_selects__2-gLg {
  cursor: pointer;
  color: var(--color-active);
  border-color: var(--color-active);
  border-radius: 4px;
  border: solid 1px;
  font-size: 1rem;
  text-align-last: center;
  padding: 0.3rem 0.5rem;
}

.header_small__3piWA {
  font-size: 0.6rem;
  margin-top: 0.15rem;
  text-align: center;
  color: var(--color-gray);
}

.header_font900__V3aHS {
  font-weight: 900;
  color: #15193b;
}

.header_font700__W40cH {
  font-weight: 700;
}

.header_font300__3Y00p {
  font-weight: 300;
  color: #29afca;
}

.header_headerRight__3Zp9V .header_active__bQuKW {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: var(--color-light-blue);
          text-decoration-color: var(--color-light-blue);
  color: var(--color-light-blue);
}

@media (max-width: 1200px) {
  .header_headerItem__xO8JM {
    padding-right: 0.5rem;
  }

  .header_headerItem__xO8JM:nth-child(2) {
    padding: 0;
  }

  .header_headerItem__xO8JM a,
  .header_headerItem__xO8JM .header_btn__1QZrw,
  .header_headerItem__xO8JM .header_selects__2-gLg {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }

  .header_headerLogo__1ECIY .header_containerDesktop__3M-sC .header_imgLogo__3ULiY {
    width: 150px;
  }
}

@media (max-width: 768px) {
  .header_headerItem__xO8JM {
    display: none;
    padding-right: 0.5rem;
  }

  .header_headerItem__xO8JM:first-child {
    display: -webkit-flex;
    display: flex;
  }

  .header_headerItem__xO8JM:nth-child(2) {
    display: -webkit-flex;
    display: flex;
    padding: 0.5rem 0;
  }

  .header_headerItem__xO8JM:nth-child(5) {
    display: -webkit-flex;
    display: flex;
    margin-left: auto;
  }

  .header_headerItem__xO8JM a {
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
  }

  .header_small__3piWA {
    margin-top: 0.05rem;
  }
}

@media (max-width: 480px) {
  .header_header__3kAwv {
    padding: 0.5rem 0.5rem 0.3rem 0.5rem;
  }

  .header_headerItem__xO8JM {
    padding: 0;
  }

  .header_headerItem__xO8JM .header_sidebarBtn__2SsRA {
    margin: 0.25rem 0;
  }

  .header_headerItem__xO8JM a {
    padding: 0.5rem;
  }

  .header_font900__V3aHS,
  .header_font700__W40cH,
  .header_font300__3Y00p {
    font-size: 0.8rem;
  }

  .header_headerItem__xO8JM .header_btn__1QZrw {
    font-size: 0.8rem;
    padding: 0.5rem 0.75rem;
  }

  .header_headerLogo__1ECIY .header_containerDesktop__3M-sC {
    display: none;
  }
  .header_headerLogo__1ECIY .header_containerMobile__aOcez {
    display: -webkit-flex;
    display: flex;
  }
}

.sidebar_sidebarOverlay__3johL {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  display: -webkit-flex;
  display: flex;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.sidebar_sidebar__1UGNI {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background: #fff;
  box-shadow: 3px 0px 12px 0px rgba(0, 0, 0, 0.2);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  padding: 1rem;
}

.sidebar_sidebar__1UGNI .sidebar_sidebarItem__36NXt {
  color: #15193b;
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
}

.sidebar_sidebar__1UGNI .sidebar_sidebarItem__36NXt:first-child {
  margin: 0 auto 3rem auto;
}

.sidebar_font900__1owlf {
  font-weight: 900;
  color: #15193b;
}

.sidebar_font700__ZxsAz {
  font-weight: 700;
}

.sidebar_font300__1A-cf {
  font-weight: 300;
  color: #29afca;
}

.sidebar_sidebar__1UGNI .sidebar_sidebarItem__36NXt:nth-child(4) {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.sidebar_sidebarItem__36NXt .sidebar_btn__ZX9Jh {
  background-color: var(--color-active);
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  padding: 0.5rem 1.5rem;
  border: none;
  font-size: 1rem;
}

.sidebar_sidebarItem__36NXt .sidebar_btn__ZX9Jh:hover {
  background: var(--color-hover);
}

.sidebar_sidebarItem__36NXt .sidebar_btn__ZX9Jh:after {
  content: "DOWNLOAD";
}
.sidebar_sidebarItem__36NXt .sidebar_btn__ZX9Jh:hover::after {
  content: "TESTE AGORA";
  background-color: var(--color-hover);
}

.sidebar_sidebarItem__36NXt .sidebar_seperator__k9rMS {
  background-color: #1699a0;
  height: 0.15rem;
  width: 16rem;
}

.sidebar_sidebarItem__36NXt .sidebar_selects__uLCI- {
  cursor: pointer;
  color: var(--color-active);
  border-color: var(--color-active);
  border-radius: 4px;
  border: solid 1px;
  font-size: 1rem;
  text-align-last: center;
  padding: 0.3rem 0.5rem;
}

.sidebar_small__2kkc6 {
  color: #333338;
  font-size: 0.6rem;
  margin-left: 0.5rem;
  text-align: center;
  color: var(--color-gray);
}

.sidebar_sidebarOverlay__3johL .sidebar_close__3OfTc {
  color: #1699a0;
  font-size: 2rem;
  margin: 1rem;
  padding: 0.5rem;
}

@media (max-width: 480px) {
  .sidebar_sidebarOverlay__3johL .sidebar_sidebar__1UGNI {
    max-width: 80%;
  }

  .sidebar_sidebar__1UGNI .sidebar_sidebarItem__36NXt {
    color: #15193b;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0.5rem;
  }

  .sidebar_sidebar__1UGNI .sidebar_sidebarItem__36NXt:first-child {
    margin: 0 auto 2rem auto;
  }

  .sidebar_sidebarItem__36NXt .sidebar_btn__ZX9Jh {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }

  .sidebar_sidebar__1UGNI .sidebar_sidebarItem__36NXt:nth-child(4) {
    margin: 0;
  }

  .sidebar_sidebarItem__36NXt .sidebar_seperator__k9rMS {
    width: 12rem;
  }

  .sidebar_sidebarItem__36NXt .sidebar_selects__uLCI- {
    font-size: 0.8rem;
  }

  .sidebar_sidebarOverlay__3johL .sidebar_close__3OfTc {
    font-size: 1.8rem;
    margin: 0.5rem;
  }
}

.footer_footer__3Wxjn {
  display: -webkit-flex;
  display: flex;
  padding-top: 5rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  text-align: left;
}

.footer_footerLeft__1H43r {
  max-width: 35%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 1rem;
  -webkit-order: 2;
          order: 2;
}

.footer_footerLeft__1H43r p {
  font-size: 0.7rem;
}

.footer_font900___d51a {
  font-weight: 900;
}

.footer_font700__sg-jG {
  font-weight: 700;
  color: var(--color-dark-blue);
  padding-bottom: 1rem;
  text-transform: uppercase;
}

.footer_font300__3fW_l {
  font-weight: 300;
}

.footer_footerRight__1N1Tk {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-order: 1;
          order: 1;
}

.footer_columnRight__TZTDN {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-right: 1rem;
  text-align: left;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  min-width: 8rem;
}

.footer_columnItems__33E8K {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.footer_footerRight__1N1Tk .footer_bottomSpace__Um26u {
  padding-bottom: 1rem;
  font-size: 1rem;
}

.footer_paymentLogos__3us-n {
  padding-top: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.footer_logo__1FKB6 {
  padding-top: 0.5rem;
  place-self: flex-start;
}

@media (max-width: 768px) {
  .footer_footer__3Wxjn {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .footer_footerLeft__1H43r {
    max-width: none;
    padding: 0;
  }

  .footer_footerRight__1N1Tk {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-bottom: 1rem;
  }

  .footer_footerRight__1N1Tk .footer_columnRight__TZTDN {
    padding: 1rem 0 0 0;
  }

  .footer_columnRight__TZTDN .footer_columnItems__33E8K {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .footer_columnRight__TZTDN .footer_columnItems__33E8K a,
  .footer_columnRight__TZTDN .footer_columnItems__33E8K button {
    padding-right: 1rem;
  }
}

@media (max-width: 480px) {
  .footer_columnRight__TZTDN .footer_columnItems__33E8K a,
  .footer_columnRight__TZTDN .footer_columnItems__33E8K button {
    font-size: 0.8rem;
  }

  .footer_font700__sg-jG {
    padding-bottom: 0.5rem;
  }

  .footer_footerRight__1N1Tk .footer_bottomSpace__Um26u {
    padding-bottom: 0.5rem;
  }
}

.afiliado-page_main__1B9Pk {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 5rem;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.afiliado-page_font300Blue__3x-1d {
  color: var(--color-light-blue);
  font-weight: 300;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.afiliado-page_spaceBottom__2fcx- {
  padding-bottom: 1rem;
}

.faq-page_main__2Ay1e {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 5rem;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.faq-page_column__1BKH7 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.faq-page_rowTop__1autq {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.faq-page_title__2nv-x {
  font-weight: 300;
  color: var(--color-light-blue);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.faq-page_leftCell__JBbts {
  width: 50%;
  padding-right: 1rem;
}

.faq-page_rightCell__3caE3 {
  width: 50%;
  padding-left: 1rem;
}

.faq-page_bottomSpace__3vaS7 {
  padding-bottom: 3rem;
}

.faq-page_spaceBottom__cng-4 {
  padding-bottom: 0.5rem;
}

.faq-page_textTitle__1Y6Bd {
  font-weight: 700;
  color: var(--color-light-blue);
}

.faq-page_rightCell__3caE3 .faq-page_textTitle__1Y6Bd {
  font-weight: 700;
  color: var(--color-light-blue);
  padding-bottom: 0.5rem;
}

.faq-page_rightCell__3caE3 .faq-page_spaceBottom__cng-4 {
  padding-bottom: 1rem;
}

.faq-page_content__TQLwS a {
  text-decoration: underline;
}

.guia-page_main__1_N_- {
  margin: auto;
  margin-top: 5rem;
  /* display: flex; */
  max-width: 1200px;
  text-align: left;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.guia-page_column__ZuXzZ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.guia-page_rowTop__2PpkW {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.guia-page_title__biRna {
  font-weight: 300;
  color: var(--color-light-blue);
  padding: 2rem;
  margin: 0 auto;
}

.guia-page_sticky__3Uy0R {
  position: -webkit-sticky;
  position: sticky;
}

.guia-page_leftCell__31jBF {
  height: 100%;
  width: 50%;
  padding: 0 1rem;
}

.guia-page_rightCell__2RH4l {
  width: 50%;
  padding: 0 1rem;
}

.guia-page_titleRight__3nMEU {
  font-weight: 700;
  padding-bottom: 0.5rem;
  color: var(--color-light-blue);
}

.guia-page_rowTop__2PpkW .guia-page_leftCell__31jBF .guia-page_btnGuia__2y9sa:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: var(--color-light-blue);
          text-decoration-color: var(--color-light-blue);
  color: var(--color-light-blue);
}

.guia-page_leftCell__31jBF .guia-page_selects__1ezvi {
  display: none;
  cursor: pointer;
  color: var(--color-active);
  border-color: var(--color-active);
  border-radius: 4px;
  border: solid 1px;
  font-size: 1rem;
  padding: 0.3rem 0.5rem;
}

.guia-page_leftCell__31jBF ol.guia-page_pills__35JDX {
  counter-reset: section;
  list-style-type: none;
  -webkit-flex-direction: column;
          flex-direction: column;
  display: -webkit-flex;
  display: flex;
  color: red;
}

.guia-page_leftCell__31jBF ol.guia-page_pills__35JDX .guia-page_spaceLeft-1__15M_E {
  padding-left: 0;
}

.guia-page_leftCell__31jBF ol.guia-page_pills__35JDX .guia-page_spaceLeft-2__17SL2 {
  padding-left: 1rem;
}

.guia-page_leftCell__31jBF ol.guia-page_pills__35JDX .guia-page_spaceTop__3VzTz {
  padding-top: 0.5rem;
}

.guia-page_leftCell__31jBF ol.guia-page_pills__35JDX li {
  color: var(--color-text);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;

  padding-bottom: 0.5rem;
}

.guia-page_leftCell__31jBF ol.guia-page_pills__35JDX .guia-page_btnGuia__2y9sa {
  display: -webkit-flex;
  display: flex;
  font-size: inherit;
}

.guia-page_leftCell__31jBF ol.guia-page_pills__35JDX .guia-page_btnGuia__2y9sa:hover,
.guia-page_leftCell__31jBF ol.guia-page_pills__35JDX .guia-page_btnGuia__2y9sa.guia-page_active__VlWNy {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: var(--color-light-blue);
          text-decoration-color: var(--color-light-blue);
  color: var(--color-light-blue);
}

.guia-page_leftCell__31jBF ol.guia-page_pills__35JDX .guia-page_btnGuia__2y9sa.guia-page_active__VlWNy {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .guia-page_title__biRna {
    font-size: 2.4rem;
  }

  .guia-page_rowTop__2PpkW {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .guia-page_leftCell__31jBF {
    padding: 0 2rem;
    width: 100%;
  }

  .guia-page_leftCell__31jBF .guia-page_selects__1ezvi {
    display: block;
    margin-bottom: 2rem;
  }

  .guia-page_leftCell__31jBF .guia-page_selects__1ezvi option {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .guia-page_leftCell__31jBF ol.guia-page_pills__35JDX {
    display: none;
  }

  .guia-page_rightCell__2RH4l {
    padding: 0 2rem;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .guia-page_title__biRna {
    font-size: 2rem;
  }

  .guia-page_leftCell__31jBF {
    padding: 0 1rem;
  }

  .guia-page_leftCell__31jBF .guia-page_selects__1ezvi {
    font-size: 0.8rem;
  }

  .guia-page_rightCell__2RH4l {
    padding: 0 1rem;
  }
}

.userGuide-topic_userGuideContent__Pb443 {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.userGuide-topic_userGuideContent__Pb443 p {
  margin-bottom: 0.25em;
}

.userGuide-topic_imagesWrapper__2n1sm {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: calc(100% + 1rem);
  margin-left: -0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.userGuide-topic_imagesWrapper__2n1sm .userGuide-topic_image__3mAfS {
  margin: 0.5rem;
  max-width: 100%;
  height: 400px;
}

.userGuide-topic_navigationBtns__mZbii {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.userGuide-topic_navigationBtns__mZbii .userGuide-topic_btn__18C2F {
  padding: 0.5em 0;
}
.userGuide-topic_navigationBtns__mZbii .userGuide-topic_btn__18C2F:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: var(--color-light-blue);
          text-decoration-color: var(--color-light-blue);
  color: var(--color-light-blue);
}

.userGuide-topic_navigationBtns__mZbii .userGuide-topic_disabledBtn__28w9G {
  opacity: 0.5;
}

.terms-conditions-page_main__1ukDZ {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 5rem;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
.terms-conditions-page_font300__3YEn3 {
  font-weight: 300;
  color: var(--color-light-blue);
  font-size: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.terms-conditions-page_fontRed__33Rzf {
  color: var(--color-error);
}

.terms-conditions-page_spaceBottom__3bLWa {
  padding-bottom: 1rem;
}

.terms-conditions-page_spaceTop__OM_Q5 {
  padding-top: 1em;
}
.terms-conditions-page_textContainer__3MuQH {
  font-size: 0.85rem;
}

.terms-conditions-page_textContainer__3MuQH p {
  font-size: inherit;
  padding-bottom: 0.6em;
}

.cancelar-page_main__1KBuY {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 5rem;
  /* display: flex; */
  text-align: left;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.cancelar-page_column__3mvQk {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.cancelar-page_rowTop__3dGK1 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.cancelar-page_title__2THgT {
  font-weight: 300;
  color: var(--color-light-blue);
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.cancelar-page_leftCell__U-UgX {
  height: 100%;
  width: 50%;
  padding-right: 1rem;
}

.cancelar-page_sticky__1HzzJ {
  position: -webkit-sticky;
  position: sticky;
}

.cancelar-page_spaceLeft__3xJwC {
  padding-left: 1rem;
}

.cancelar-page_rightCell__96sV1 {
  width: 50%;
  padding-left: 1rem;
}

.cancelar-page_smallTextGray__1uUI5 {
  font-size: 0.7rem;
  color: var(--color-gray);
  padding-top: 1rem;
}

.cancelar-page_smallText__2a4vl {
  font-size: 0.7rem;
}
.cancelar-page_centerItems__2jD6f {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-bottom: 1rem;
}

.cancelar-page_vendorText__fe2Vs {
  font-size: 0.7rem;
  color: var(--color-light-blue);
  cursor: pointer;
}

.cancelar-page_input__fGG02 {
  border: solid 1px #1699a0;
  text-align: center;
  font-size: 18px;
  height: 50px;
  width: 300px;
}

.cancelar-page_textSmall__1t4oJ {
  font-size: 0.7rem;
  padding-bottom: 1rem;
}

.cancelar-page_centerItems__2jD6f .cancelar-page_BtnDeact__DV1oB {
  background-color: var(--color-gray);
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border: none;
  font-size: 1rem;
  vertical-align: -0.05rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-top: 2.7rem; 
  text-transform: uppercase;
  font-weight: 700;
}

.cancelar-page_centerItems__2jD6f .cancelar-page_BtnAct__3fs85 {
  background-color: var(--color-active);
  /* color: #ffffff;
  text-align: center;
  border-radius: 8px;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border: none;
  font-size: 1rem;
  vertical-align: -0.05rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-top: 2.7rem; */
}

.details-page_title__30pqv {
  font-weight: 700;
  font-size: 1rem;
}
.details-page_textCenter__u49r- {
  text-align: center;
  padding: 0 2rem 4rem 3rem;
}

.details-page_descrTitle__1ciLa {
  font-weight: 900;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 2rem;
}

.details-page_titleInputBetfair__12X23 {
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 0.5rem;
}

.details-page_paddingBottom__1cjFs {
  padding-bottom: 1.5rem;
}

.details-page_paddingTop__3wr65 .details-page_inputCheckBox__2XBIv:checked,
.details-page_paddingTop__3wr65 .details-page_inputCheckBox__2XBIv span {
  color: var(--color-light-blue);
}

.details-page_selects__L-jhz {
  color: #1699a0;
  border-color: #1699a0;
  border-radius: 4px;
  border: solid 1px;
  margin-bottom: 2rem;
}

.details-page_titleInput__C22AD {
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 2rem;
}

.details-page_input__1AiDN {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.5rem;
  font-size: 1rem;
  border-style: solid;
  border-color: #29afca;
  border-width: 1px;
}

.details-page_inputInvalid__2XSTe {
  border-color: red;
}

.details-page_row__uYpKF {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.details-page_leftCell__1gYrI {
  width: 50%;
  padding-left: 2rem;
  text-align: left;
}

.details-page_row__uYpKF .details-page_confBtnAct__3Tj2u,
.details-page_row__uYpKF .details-page_confBtnDeact__1nQjk {
  background-color: var(--color-active);
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border: none;
  font-size: 1rem;
  vertical-align: -0.05rem;
  margin-left: 0.5rem;
  margin-top: 0.6rem;
  text-transform: uppercase;
  -webkit-align-self: flex-start;
          align-self: flex-start;
}

.details-page_row__uYpKF .details-page_confBtnDeact__1nQjk {
  background-color: var(--color-gray);
}

.details-page_row__uYpKF .details-page_vendorText__T7Eip {
  color: var(--color-active);
  border-color: var(--color-active);
  cursor: pointer;
}

.details-page_row__uYpKF .details-page_vendorClientInput__1ah4n {
  color: var(--color-active);
  border-color: var(--color-active);
  cursor: pointer;
}

.details-page_betFairForm__n_Ku- {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.details-page_betFairForm__n_Ku- .details-page_textInputBox__2VMcy {
  display: -webkit-flex;
  display: flex;
  width: 60%;
}

.details-page_betFairForm__n_Ku- .details-page_textInputBox__2VMcy .details-page_vendorClientInput__1ah4n,
.details-page_betFairForm__n_Ku- .details-page_textInputBox__2VMcy .details-page_vendorClientInputError__k9Z6U {
  width: 100%;
}

.details-page_betFairForm__n_Ku- .details-page_textInputBox__2VMcy .details-page_vendorClientInput__1ah4n fieldset {
  border-color: var(--color-active);
  border-width: 2px;
}

.details-page_betFairForm__n_Ku-
  .details-page_textInputBox__2VMcy
  .details-page_vendorClientInput__1ah4n
  label[id="bf-vendor-client-id-label"] {
  color: var(--color-active);
}

.details-page_betFairForm__n_Ku- .details-page_textInputBox__2VMcy .details-page_vendorClientInputError__k9Z6U {
  color: red;
  border-color: red;
  cursor: pointer;
}

.details-page_betFairForm__n_Ku- .details-page_textInputBox__2VMcy .details-page_vendorClientInputError__k9Z6U fieldset {
  border-color: red;
  border-width: 2px;
}

.details-page_countrySelectForm__10zG7 {
  padding-top: 1.5rem;
}

.details-page_countrySelectForm__10zG7 .details-page_countrySelectInput__2WC2d,
.details-page_countrySelectForm__10zG7 .details-page_countrySelectInput__2WC2d label,
.details-page_countrySelectForm__10zG7 .details-page_countrySelectInput__2WC2d label:focus {
  color: var(--color-active);
}

.details-page_countrySelectForm__10zG7 .details-page_countrySelectInput__2WC2d {
  width: 100%;
}

.details-page_countrySelectInput__2WC2d > div:first-of-type {
  width: 60%;
}

.details-page_textFieldBox__1Iig9 {
  padding-top: 1.5rem;
  width: 100%;
}

.details-page_invoice__ulzAe {
  display: -webkit-flex;
  display: flex;
}

.details-page_inputDetails__2GuAe {
  display: -webkit-flex;
  display: flex;
  width: 60%;
}

.details-page_invoiceDetails__pnNvx .details-page_countrySelectForm__10zG7 .details-page_countrySelectInput__2WC2d fieldset {
  border-width: 2px;
  border-color: var(--color-active);
}

.details-page_inputDetails__2GuAe .details-page_formControl__2F1JH {
  width: 100%;
}

.details-page_inputDetails__2GuAe .details-page_formControl__2F1JH label {
  color: var(--color-active);
  border-color: var(--color-active);
}

.details-page_invoice__ulzAe .details-page_checkIcon__un2CA {
  -webkit-align-self: center;
          align-self: center;
}

.details-page_textFieldBox__1Iig9 .details-page_inputDetails__2GuAe .details-page_formControl__2F1JH .details-page_textField__wNQkb > div:first-of-type,
.details-page_textFieldBox__1Iig9 .details-page_inputDetails__2GuAe .details-page_formControl__2F1JH .details-page_textField__wNQkb fieldset {
  border-color: var(--color-active);
  border-width: 2px;
}

.details-page_inputDetails__2GuAe .details-page_formControl__2F1JH label[class~="Mui-error"] {
  color: red;
}

.details-page_rightCell__3TVw8 {
  width: 50%;
  padding: 0 2rem;
}

.details-page_cell__JhfoP {
  background-color: var(--color-pale);
  padding-left: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  text-align: left;
}

.details-page_rightCellTitle__3W-Ei {
  color: #29afca;
  padding-bottom: 2rem;
  font-weight: bold;
}

.details-page_ammount__2w87A {
  text-align: right;
}

.details-page_centerItems__3Tbzy {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-bottom: 1rem;
}

.details-page_alignRight__3v9BJ {
  float: right;
}

.details-page_totalSize__2PJ2n {
  font-size: 1.3rem;
}

.details-page_hidden__3PHR_ {
  display: none;
}

.details-page_formCell__2sCJQ {
  background-color: white;
  padding-left: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  margin-left: 0rem;
  margin-right: 2rem;
}

.details-page_newForm__a7TKB {
  padding-top: 1rem;
  font-weight: bold;
}

.details-page_emptySpace__12VKc {
  padding-top: 3rem;
}

.details-page_summary__CvaR3 {
  padding-top: 1rem;
}

.details-page_continueBtn__OBokE {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.details-page_continueBtn__OBokE .details-page_BtnAct__2mMXD,
.details-page_continueBtn__OBokE .details-page_BtnDeact__1_JH9 {
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  border: none;
  font-size: 1rem;
  vertical-align: -0.05rem;

  margin-top: 1rem;
  text-transform: uppercase;
}

.details-page_continueBtn__OBokE .details-page_BtnAct__2mMXD {
  background-color: var(--color-active);
}

.details-page_continueBtn__OBokE .details-page_BtnDeact__1_JH9 {
  background-color: var(--color-gray);
}

.details-page_icons__1hT0Q {
  -webkit-align-self: center;
          align-self: center;
  display: -webkit-flex;
  display: flex;
  height: 100%;
}

.details-page_icon__3OzCe {
  color: var(--color-active);
  margin: 0 0.25rem;
}

.details-page_betfairVendor__15t6x {
  padding-top: 1rem;
}

@media (max-width: 768px) {
  .details-page_row__uYpKF {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .details-page_leftCell__1gYrI {
    padding: 0 0 1rem 0;
    width: 100%;
  }

  .details-page_formCell__2sCJQ {
    margin: 0;
  }

  .details-page_rightCell__3TVw8 {
    padding: 0;
    width: 100%;
  }

  /* .inputWrapper {
    padding-left: 1rem;
    width: 100%;
  } */
}

@media (max-width: 480px) {
  .details-page_leftCell__1gYrI {
    padding: 0 1rem 1rem 1rem;
  }

  .details-page_rightCell__3TVw8 {
    padding: 0 1rem;
  }
}

.download-page_main__HzhjM {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 5rem;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.download-page_column__1RsKm {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.download-page_rowTop__Iya0Y {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.download-page_title__3OgdD {
  font-weight: 300;
  color: var(--color-light-blue);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.download-page_leftCell__3oEdc {
  width: 50%;
  padding-right: 1rem;
}

.download-page_rightCell__hVdA2 {
  width: 50%;
  padding-left: 1rem;
}

.download-page_font900__1piE- {
  font-weight: 900;
}

.download-page_font300__ydQaA {
  font-weight: 300;
}

.download-page_bottomSpace__20gRb {
  padding-bottom: 3rem;
}

.download-page_spaceBottom__2poEX {
  padding-bottom: 0.5rem;
}

.download-page_textTitle__20YtM {
  padding-top: 1rem;
  font-weight: 700;
  color: var(--color-light-blue);
  padding-bottom: 1rem;
}

.download-page_darkBlue__e2F6f {
  color: var(--color-dark-blue);
}
.download-page_containerBtns__Iyp5b {
  display: -webkit-flex;
  display: flex;
  padding-top: 1rem;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.download-page_cell__2P90e {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.download-page_cell__2P90e .download-page_btnOs__24mw- {
  padding: 3rem 2rem;
  background-color: white;
  color: black;
  border: 1px solid var(--color-light-blue);
  border-radius: 4px;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

.download-page_cell__2P90e .download-page_btnOsActive__15JS3 {
  background-color: #23214b;
  color: #ffffff;
}

.download-page_btnDownload__3dJnD {
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.download-page_btnDownload__3dJnD .download-page_btn__1uFWu {
  background-color: var(--color-active);
  color: var(--color-background);
  text-align: center;
  border-radius: 4px;
  height: 2rem;
  width: 10rem;
  border: none;
  font-size: 1rem;
}
.download-page_btnDownload__3dJnD .download-page_btn__1uFWu.download-page_btnDisable__2cBep {
  background-color: var(--color-gray);
  color: var(--color-background);
  text-align: center;
  border-radius: 4px;
  height: 2rem;
  width: 10rem;
  border: none;
  font-size: 1rem;
}
.download-page_smallText__2qkeL {
  font-size: 0.7rem;
  color: var(--color-gray);
  padding-right: 3.5rem;
  padding-left: 3.5rem;
  padding-top: 0.5rem;
}
ul li {
  list-style-type: circle;
  padding-bottom: 0.5rem;
  line-height: 1.2em;
}

.download-page_betfair__9b8UR {
  font-weight: 700;
  color: #15193b;
}

.download-page_conta__2ePAJ {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.download-page_icon__1aSbe {
  height: 1rem;
  width: 1rem;
  background-position: center;
  padding-right: 2.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-filter: invert(100%) sepia(6%) saturate(0%) hue-rotate(313deg)
    brightness(105%) contrast(106%);
          filter: invert(100%) sepia(6%) saturate(0%) hue-rotate(313deg)
    brightness(105%) contrast(106%);
}

.download-page_buttonTab__1U1wa .download-page_borderBottom__2Toub {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 0.2rem;
  background-color: var(--color-gray);
  border-radius: 0.4rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  min-width: 8rem;
}

.download-page_buttonTab__1U1wa .download-page_text__2WSKG {
  padding-bottom: 0.3rem;
  font-weight: 700;
}

.download-page_btnOS__1QjgJ.download-page_active__WhGfn .download-page_text__2WSKG {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: var(--color-light-blue);
          text-decoration-color: var(--color-light-blue);
  color: var(--color-light-blue);
}

.download-page_btnOS__1QjgJ.download-page_active__WhGfn .download-page_buttonTab__1U1wa .download-page_borderBottom__2Toub {
  background-color: var(--color-light-blue);
}

.download-page_icon__1aSbe {
  height: 1rem;
  width: 1rem;
  background-position: center;
  padding-right: 2.5rem;
  background-size: contain;
  background-repeat: no-repeat;
}
.download-page_icon__1aSbe.download-page_windows__2tOsf {
  background-image: url(/static/media/windows-logo.0333f882.svg);
}
.download-page_icon__1aSbe.download-page_mac__5kelm {
  background-image: url(/static/media/apple-logo.f73cc843.svg);
}
.download-page_icon__1aSbe.download-page_linux__D1Kj0 {
  background-image: url(/static/media/linux-logo.45ec81d0.svg);
}

.loja-page_main__34_Od {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 5rem;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.loja-page_textCenter__2x-Td {
  text-align: center;
  padding: 0 0 3rem 0;
}

.loja-page_titleCenter__3opTe {
  font-size: 1.7rem;
  padding: 2rem 2rem 0 2rem;
}

.loja-page_title__2fedb {
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 2rem;
}

.loja-page_container__d5j4v {
  text-align: center;
  padding: 3rem 2rem 3rem 2rem;
}

@media (max-width: 768px) {
  .loja-page_container__d5j4v {
    padding: 2rem 1rem 2rem 1rem;
  }
}

@media (max-width: 480px) {
  .loja-page_container__d5j4v {
    padding: 2rem 0;
  }
}

.store-tab_buttonTab__tm7_8 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 100%;
}

.store-tab_cell__1QMKb {
  -webkit-align-items: flex-end;
          align-items: flex-end;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 4rem;
  width: 100%;
}

.store-tab_cell__1QMKb .store-tab_btnOS__3dhk6 {
  text-align: left;
  width: calc(100% / 4 - 0.5rem);
}

.store-tab_buttonTab__tm7_8 .store-tab_borderBottom__fREF1 {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 0.2rem;
  background-color: var(--color-gray);
  border-radius: 0.4rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.store-tab_buttonTab__tm7_8 .store-tab_borderBottom__fREF1.store-tab_noColor__CC0oX {
  background-color: unset;
}

.store-tab_buttonTab__tm7_8 .store-tab_hoverSpan__3kp55 {
  background-color: var(--color-hover);
}
.store-tab_buttonTab__tm7_8 .store-tab_activeSpan__2BQuv {
  background-color: var(--color-light-blue);
}
.store-tab_buttonTab__tm7_8 .store-tab_text__1KVfu {
  padding-bottom: 0.3rem;
  font-weight: 700;
}

.store-tab_buttonTab__tm7_8 .store-tab_hoverText__3YEEI {
  color: var(--color-hover);
}

.store-tab_buttonTab__tm7_8 .store-tab_active__2-kCS {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: var(--color-light-blue);
          text-decoration-color: var(--color-light-blue);
  color: var(--color-light-blue);
}

@media (max-width: 768px) {
  .store-tab_cell__1QMKb {
    padding: 0 2rem;
  }

  .store-tab_cell__1QMKb .store-tab_btnOS__3dhk6 p {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .store-tab_cell__1QMKb {
    padding: 0 1rem;
  }
}

.summaryStore_cell__3XV6J {
  background-color: var(--color-pale);
  padding-left: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  text-align: left;
}
.summaryStore_rightCellTitle__CGquT {
  color: #29afca;
  padding-bottom: 2rem;
  font-weight: bold;
}
.summaryStore_alignRight__1hZH6 {
  float: right;
}

.summaryStore_totalSize__14kat {
  font-size: 1.3rem;
}
.summaryStore_summary__1UNg7 {
  padding-top: 1rem;
}
.summaryStore_descrTitle__2F52H {
  font-weight: 900;
  font-size: 1rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.summaryStore_oldPrice__1a5xi {
  text-decoration: line-through;
}
.summaryStore_newPrice__387i- {
  color: var(--color-light-blue);
}

.summaryStore_smallText__1Q5ZS {
  font-size: 0.6rem;
}

.paymentMethods-page_paymentContainer__3QdhP {
  display: -webkit-flex;
  display: flex;
  padding-bottom: 3rem;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.paymentMethods-page_textCenter__1caNp {
  text-align: center;
  padding: 0 2rem 4rem 3rem;
}

.paymentMethods-page_title__3dYC1 {
  font-weight: 700;
  font-size: 1rem;
}

.paymentMethods-page_leftCell__3RxEh {
  width: 50%;
  padding-left: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.paymentMethods-page_leftCell__3RxEh .paymentMethods-page_selects__LTxCW span,
.paymentMethods-page_leftCell__3RxEh .paymentMethods-page_selects__LTxCW:checked {
  color: var(--color-light-blue);
}

.paymentMethods-page_rightCell__2cmxR {
  width: 50%;
  padding: 0 2rem;
}

.paymentMethods-page_row__wI_fe {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.paymentMethods-page_ammount__wfIBm {
  text-align: right;
}

.paymentMethods-page_centerItems__248R3 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-bottom: 1rem;
}

.paymentMethods-page_continueBtn__1Hqus {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.paymentMethods-page_continueBtn__1Hqus .paymentMethods-page_BtnAct__p2aUA,
.paymentMethods-page_continueBtn__1Hqus .paymentMethods-page_BtnDeact__a11SB {
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  border: none;
  font-size: 1rem;
  vertical-align: -0.05rem;
  margin-top: 1rem;
  text-transform: uppercase;
}

.paymentMethods-page_continueBtn__1Hqus .paymentMethods-page_BtnAct__p2aUA {
  background-color: var(--color-active);
}

.paymentMethods-page_continueBtn__1Hqus .paymentMethods-page_BtnDeact__a11SB {
  background-color: var(--color-gray);
}

@media (max-width: 768px) {
  .paymentMethods-page_row__wI_fe {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .paymentMethods-page_leftCell__3RxEh {
    padding: 0 0 1rem 0;
    width: 100%;
  }

  .paymentMethods-page_leftCell__3RxEh .paymentMethods-page_row__wI_fe {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .paymentMethods-page_formCell__2WJMy {
    margin: 0;
  }

  .paymentMethods-page_rightCell__2cmxR {
    padding: 0;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .paymentMethods-page_leftCell__3RxEh {
    padding: 0 1rem 1rem 1rem;
  }

  .paymentMethods-page_rightCell__2cmxR {
    padding: 0 1rem;
  }
}

.confirmation-page_confirmationContainer__pH_Vf {
  display: -webkit-flex;
  display: flex;
  padding-bottom: 3rem;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.confirmation-page_title__zn5_0 {
  font-weight: 700;
  font-size: 1rem;
}

.confirmation-page_textCenter__2TCrm {
  text-align: center;
  padding: 0 2rem 4rem 3rem;
}

.confirmation-page_descrTitle__2FuYj {
  font-weight: 900;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 2rem;
}

.confirmation-page_titleInputBetfair__33djc {
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 0.5rem;
}

.confirmation-page_paddingTop__1WPLd {
  padding-top: 1.5rem;
}

.confirmation-page_paddingTop__1WPLd .confirmation-page_inputCheckBox__z31oc:checked,
.confirmation-page_paddingTop__1WPLd .confirmation-page_inputCheckBox__z31oc span {
  color: var(--color-light-blue);
}

.confirmation-page_selects__3QwMm {
  color: var(--color-active);
  border-color: var(--color-active);
  border-radius: 4px;
  border: solid 1px;
  margin-bottom: 2rem;
  padding-top: 0.5rem;
}

.confirmation-page_titleInput__KdPFn {
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
}

.confirmation-page_input__3GR6t {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.5rem;
  font-size: 1rem;
  border: solid 1px var(--color-active);
  background-color: var(--color-pale);
}
.confirmation-page_row__CE83M {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.confirmation-page_leftCell__1qRGW {
  width: 50%;
  padding: 0 2rem;
  text-align: left;
}

.confirmation-page_rightCell__3womc {
  width: 50%;
  padding-left: 2rem;
  padding-right: 2rem;
}

.confirmation-page_centerItems__2KjMJ {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-bottom: 1rem;
}

.confirmation-page_alignRight__PQ8_e {
  float: right;
}

.confirmation-page_totalSize__Hq7RF {
  font-size: 1.3rem;
}

.confirmation-page_formCell__1vts7 {
  background-color: white;
  padding-left: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  margin-left: 0rem;
  margin-right: 2rem;
}

.confirmation-page_newForm__20Cl3 {
  padding-top: 1rem;
  font-weight: bold;
}

.confirmation-page_emptySpace__2QEwN {
  padding-top: 3rem;
}

.confirmation-page_continueBtn__3kbY- {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.confirmation-page_continueBtn__3kbY- .confirmation-page_contBtnAct__aKTrU {
  background-color: var(--color-active);
  color: var(--color-background);
  text-align: center;
  border-radius: 4px;
  border: none;
  font-size: 1rem;
  vertical-align: -0.05rem;
  margin-top: 1rem;
  text-transform: uppercase;
  height: 2rem;
  width: 10rem;
}

.confirmation-page_continueBtn__3kbY- .confirmation-page_contBtnAct__aKTrU div {
  padding-right: 0.3rem;
  padding-left: 0.3rem;
}

.confirmation-page_blueTitle__kq3Ss {
  color: var(--color-light-blue);
  padding-bottom: 1rem;
  font-weight: bold;
}

.confirmation-page_blueTitlePadding__2e3Qp {
  color: #29afca;
  font-weight: bold;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.confirmation-page_target__18nBs .confirmation-page_selects__3QwMm span,
.confirmation-page_target__18nBs .confirmation-page_selects__3QwMm:disabled {
  color: var(--color-light-blue);
}

.confirmation-page_spaceBottom__bYKki {
  padding-bottom: 1rem;
}

.confirmation-page_error__1BhTs {
  color: var(--color-error);
}

.confirmation-page_loadingParent__253Ji {
  position: relative;
  max-height: 1rem;
  max-width: 1rem;
}

.confirmation-page_loadingContainer__12qcl {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.25);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.confirmation-page_loadingContainer__12qcl div {
  border: 0.2em solid transparent;
  border-top: 0.2em solid var(--color-background);
  border-bottom: 0.2em solid var(--color-background);
  height: 1rem;
  width: 1rem;
}

.confirmation-page_small__hc7tq {
  font-size: 0.6rem;
  padding-top: 0.5rem;
}

@media (max-width: 768px) {
  .confirmation-page_row__CE83M {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .confirmation-page_leftCell__1qRGW {
    padding: 0 0 1rem 0;
    width: 100%;
  }

  .confirmation-page_leftCell__1qRGW .confirmation-page_row__CE83M {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .confirmation-page_formCell__1vts7 {
    margin: 0;
  }

  .confirmation-page_rightCell__3womc {
    padding: 0;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .confirmation-page_leftCell__1qRGW {
    padding: 0 1rem 1rem 1rem;
  }

  .confirmation-page_rightCell__3womc {
    padding: 0 1rem;
  }
}

