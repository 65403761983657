.priceCards {
  display: flex;
  padding: 0 2rem 2rem 2rem;
}

.priceCards .btn {
  background-color: var(--color-active);
  color: var(--color-background);
  flex-direction: column;
  text-align: center;
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  left: 50%;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 50%);
  text-transform: uppercase;
  -webkit-transform: translate(-50%, 50%);
  -moz-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  -o-transform: translate(-50%, 50%);
}
.priceCards .btn:hover {
  background: var(--color-hover);
}

.priceCards .cell {
  background-color: var(--color-pale);
  color: var(--color-text);
  margin-right: 0.5rem;
  width: 33%;
  padding: 1.5rem 2rem;
  text-align: left;
  position: relative;
  cursor: pointer;
}

.priceCards .cell:last-child {
  margin: 0;
}

.priceCards .cell .price {
  font-size: 1.6rem;
  font-weight: 300;
}

.priceCards .title {
  font-weight: 700;
  font-size: 1.1rem;
}

.priceCards .text {
  font-size: 0.8rem;
  padding-bottom: 1.5rem;
  color: var(--color-gray);
}

.discount {
  display: flex;
  padding-bottom: 0.5rem;
}

.oldPrice {
  text-decoration: line-through;
}
.newPrice {
  color: var(--color-light-blue);
  padding-left: 0.5rem;
}

.home {
  content: "Comprar";
}

@media (max-width: 1024px) {
  .priceCards .cell {
    padding: 1.5rem;
  }

  .priceCards .cell .price {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .priceCards {
    flex-direction: column;
    padding: 0 1rem 1rem 1rem;
  }

  .priceCards .cell {
    margin: 0 0 2rem 0;
    width: 100%;
  }

  .priceCards .cell .price {
    font-size: 1.6rem;
  }
}

@media (max-width: 480px) {
  .priceCards {
    padding: 0 1rem 1rem 1rem;
  }

  .priceCards .cell {
    padding: 0.5rem 1rem;
  }

  .priceCards .cell .price {
    font-size: 1.4rem;
  }
}
