.userGuideContent {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.userGuideContent p {
  margin-bottom: 0.25em;
}

.imagesWrapper {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 1rem);
  margin-left: -0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.imagesWrapper .image {
  margin: 0.5rem;
  max-width: 100%;
  height: 400px;
}

.navigationBtns {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.navigationBtns .btn {
  padding: 0.5em 0;
}
.navigationBtns .btn:hover {
  text-decoration-line: underline;
  text-decoration-color: var(--color-light-blue);
  color: var(--color-light-blue);
}

.navigationBtns .disabledBtn {
  opacity: 0.5;
}
