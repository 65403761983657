.main {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 5rem;
  flex-grow: 1;
}

.font300Blue {
  color: var(--color-light-blue);
  font-weight: 300;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.spaceBottom {
  padding-bottom: 1rem;
}
